import React, {useState} from "react";
import {loadConfig} from "../../services/ConfigService";
import EventReportFilters from "./EventReportFilters";

function ClubActivityReport(): JSX.Element {
    async function onClick() {
        const config = await loadConfig();
        let apiUrl = `${config.apiOrigin}/admin/reports/clubActivityReport`;
        apiUrl += `?eventFilterStart=${filterState.dateRangeStart}`;
        apiUrl += `&eventFilterEnd=${filterState.dateRangeEnd}`;
        apiUrl += `&eventFilterTimeZone=${filterState.dateRangeTimeZone}`;
        window.location.href = apiUrl;
    }

    const [filterState, setFilterState] = useState();

    return (
        <div className="ClubActivityReport">
            <div className="ClubActivityReport_Filters">
                <h3>Filters</h3>
                <EventReportFilters onChange={(state: object) => setFilterState(state)}/>
            </div>
            <button onClick={onClick}>Export</button>
        </div>
    );
}

export default ClubActivityReport;
