import React, {useContext} from "react";
import "./TechAdmin.scss";
import {AdminContext} from "../../contexts/AdminContext";
import ResendWelcomeEmails from "../../components/resendWelcomeEmails/ResendWelcomeEmails";
import RegeocodeLocations from "../../components/regeocodeLocations/RegeocodeLocations";
import SendTestEmail from "../../components/sendTestEmail/SendTestEmail";
import ListEventImportExportJobRecords
    from "../../components/listEventImportExportJobRecords/ListEventImportExportJobRecords";
import DuplicateEventFinder
    from "../../components/duplicateEventFinder/DuplicateEventFinder";

export default function TechAdminPage(): JSX.Element {
    const {admin} = useContext(AdminContext);

    return (
        <div className="TechAdmin">
            {admin !== null && (
                <>
                    <div>
                        <h1>Re-Geocode all Locations</h1>
                        <RegeocodeLocations/>
                    </div>
                    <div>
                        <h1>Send Test Email</h1>
                        <SendTestEmail/>
                    </div>
                    <div>
                        <h1>Review and Confirm Imports</h1>
                        <ListEventImportExportJobRecords/>
                    </div>
                </>
            )}
        </div>
    );
}
