import React from "react";

import "./Footer.scss";
import logo from "../../assets/logo.svg";
import Copyright from "./Copyright";

export default function Footer(): JSX.Element {

    return (
        <div>
            <div className={`Footer`}>
                <div className={`FooterContent`}>
                    <div className={`LogoAndCopyright`}>
                        <img className={`Logo`} src={logo} alt="logo"/>
                        <Copyright/>
                    </div>
                </div>
            </div>
        </div>
    );
}