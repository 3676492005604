import React, {useEffect} from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {LocalDate} from "@js-joda/core";
import {Locale} from "@js-joda/locale_en-us";
import {FormControl, FormLabel, MenuItem, Select} from "@material-ui/core";
import {labelForTimeZone, SupportedTimeZone} from "../../util/SupportedTimeZone";
import {LocalDateUtils} from "../../util/FixedJsJodaUtils";
import {useFormState} from "react-use-form-state";
import "./EventReportFilters.scss";

interface EventReportFilterProperties {
    //function that exposes the filter form state. See the react-use-form-state docs for the structure of this object.
    onChange: Function
}

export const initialState = {
    dateRangeStart: LocalDate.now().minusMonths(1).toString(),
    dateRangeEnd: LocalDate.now().toString(),
    dateRangeTimeZone: SupportedTimeZone.EASTERN
}

export default function EventReportFilters(props : EventReportFilterProperties) {

    const [formState, {raw}] = useFormState(initialState, {
        onChange(e, stateValues, nextStateValues) {
            props.onChange(nextStateValues);
        }
    });
    const onChange = props.onChange;

    function parse(dateValue:string) {
        try {
            return LocalDate.parse(dateValue);
        } catch(e) {
            return "";
        }
    }

    useEffect(() => {
        //propagate the initial form state up to any subscribing components
        onChange(formState.values);
    }, [onChange, formState.values])

    return (<div className="EventReportFilter_Wrapper">
      <h4>Event Starts At Range</h4>
      <div className="EventReportFilter_DateFilterWrapper">
          <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
              <div className="EventReportFilter_DateInputWrapper">
                  <FormControl>
                      <FormLabel>Start</FormLabel>
                      <KeyboardDatePicker className={"EventDataDateInput"}
                                          {...raw({
                                              name: 'dateRangeStart',
                                              touchOnChange: true,
                                              onChange: date => date.toString()
                                          })}
                                          value={parse(formState.values.dateRangeStart)}
                                          variant="inline"
                                          format="MM/dd/yyyy"/>
                  </FormControl>
              </div>
              <div className="EventReportFilter_DateInputWrapper">
                  <FormControl>
                      <FormLabel>End</FormLabel>
                      <KeyboardDatePicker
                          {...raw({
                              name: 'dateRangeEnd',
                              touchOnChange: true,
                              onChange: date => date.toString()
                          })}
                          value={parse(formState.values.dateRangeEnd)}
                          variant="inline"
                          format="MM/dd/yyyy"/>
                  </FormControl>
              </div>
              <FormControl>
                  <Select required
                          {...raw({
                              name: 'dateRangeTimeZone',
                              touchOnChange: true,
                              onChange: timeZone => timeZone.target.value
                          })}
                          variant="outlined">
                      {Object.values(SupportedTimeZone).map(supportedTimeZone => (
                          <MenuItem key={supportedTimeZone}
                                    value={supportedTimeZone}>
                              {labelForTimeZone(supportedTimeZone)}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
          </MuiPickersUtilsProvider>
      </div>
  </div>)
}