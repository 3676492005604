import React, {useContext} from "react";
import "./HomePage.scss";
import {AdminContext} from "../../contexts/AdminContext";
import ManageUsersList from "../../components/home/ManageUsersList";
import ManageClubsList from "../../components/home/ManageClubsList";
import MemberActivityReport from "../../components/reports/MemberActivityReport";
import EventDataExport from "../../components/reports/EventDataExport";
import AttendanceDataExport from "../../components/reports/AttendanceDataExport";
import UserReport from "../../components/reports/UserReport";
import ClubActivityReport from "../../components/reports/ClubActivityReport";
import ManagePassionsList from "../../components/home/ManagePassionsList";
import ActivityTrackerMonthlyMemberReport from "../../components/reports/ActivityTrackerMonthlyMemberReport";

export default function HomePage(): JSX.Element {
    const {admin} = useContext(AdminContext);

    return (
        <div className="HomePage">
            {admin !== null && (
                <>
                    <div>
                        <h1>Activity Tracker Monthly Member Report</h1>
                        <ActivityTrackerMonthlyMemberReport/>
                    </div>
                    <div>
                        <h1>Member Activity Report</h1>
                        <MemberActivityReport/>
                    </div>
                    <div>
                        <h1>Club Activity Report</h1>
                        <ClubActivityReport/>
                    </div>
                    <div>
                        <h1>Export Event Data for Optum</h1>
                        <EventDataExport/>
                    </div>
                    <div>
                        <h1>Export Attendance Data for Optum</h1>
                        <AttendanceDataExport isVirtual={false}/>
                    </div>
                    <div>
                        <h1>Export Virtual Attendance Data for Optum</h1>
                        <AttendanceDataExport isVirtual={true}/>
                    </div>
                    <div>
                        <h1>Generate Member Registrations Report</h1>
                        <UserReport/>
                    </div>
                    <div>
                        <h1>Manage Users</h1>
                        <ManageUsersList/>
                    </div>
                    <div>
                        <h1>Manage Clubs/Associations</h1>
                        <ManageClubsList/>
                    </div>
                    <div>
                        <h1>Manage Passions</h1>
                        <ManagePassionsList/>
                    </div>
                </>
            )}
        </div>
    );
}
