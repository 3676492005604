import React, {useContext, useState} from "react";
import "./LoginForm.scss";
import {AdminContext} from "../../contexts/AdminContext";
import * as AdminService from "../../services/AdminService";
import { Redirect } from "react-router-dom";

export default function LoginForm(): JSX.Element {
    const {login} = useContext(AdminContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginResult, setLoginResult] = useState("");
    const [isLoggedIn, setLoggedIn] = useState(false);

    function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function onChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    async function onSubmitLoginForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoginResult("Logging in...");
        try {
            const isLoggedIn = await login(email, password);
            if (!isLoggedIn) {
                setLoginResult("Invalid login!");
                console.log("Invalid login!");
                return;
            }
            await AdminService.test();
            setLoginResult("It worked!");
            setLoggedIn(true);
        } catch (e) {
            setLoginResult("It failed!\n" + e.stack);
            console.error("Error", e);
        }
    }

    return (
        <>
            { isLoggedIn && <><Redirect to={"/"}/></> }
            { !isLoggedIn && <>
                <div className="LoginForm">
                    <form onSubmit={onSubmitLoginForm}>
                        <div className={"Grid"}>
                            <div className={"EmailLabel"}>
                                <label>
                                    Email:
                                </label>
                            </div>
                            <div className={"Email"}>
                                <input type="email" value={email} onChange={onChangeEmail} autoFocus={true}/>
                            </div>
                            <div className={"PasswordLabel"}>
                                <label>
                                    Password:
                                </label>
                            </div>
                            <div className={"Password"}>
                                <input type="password" value={password} onChange={onChangePassword}/>
                            </div>
                            <div className={"ButtonAndResult"}>
                                <button type="submit">Login</button>
                                <div className={"LoginResult"}>{loginResult}</div>
                            </div>
                        </div>
                    </form>
                </div>
            </> }
        </>
    );
}
