import React from "react";
import "./Version.scss"

export function GetVersion() {
    return "1.0.8";
}

function Version() : JSX.Element {
    return (<span className={"Version"}>Version {GetVersion()}</span>);
}

export default Version;