import {
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react'

import { loadConfig } from "../../src/services/ConfigService";
import { AdminContext } from "../../src/contexts/AdminContext";

interface PassionResponseBody {
  id: number;
  passionName: string;
  imageFile: string;
  imageUrl: string;
  category: string;
}

interface GetAllPassionsResponseBody {
  allPassions: PassionResponseBody[];
}

export const useAllPasions = () => {
    const [passions, setPassions] = useState<PassionResponseBody[]>([]);

    const { authenticatedFetch } = useContext(AdminContext);

    const fetchPassions = useCallback(async () => {
        const config = await loadConfig();
        const request = new Request(`${config.apiOrigin}/admin/passions/getAllPassions`);
        const response = await authenticatedFetch(request);
  
        if (response === null || !response.ok) {
            alert("There was an error.");
            return;
        }
  
        const responseBody: GetAllPassionsResponseBody = await response.json();
  
        setPassions(responseBody.allPassions);
    }, [authenticatedFetch]);

    useEffect(() => {
      fetchPassions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [passions, fetchPassions] as const;
};
