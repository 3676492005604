import {useCallback, useContext, useEffect, useState} from "react";
import {loadConfig} from "../services/ConfigService";
import {AdminContext} from "../contexts/AdminContext";

export const useSearchDistinctUsersByEmailOrName = (searchTerm: string) => {
    const { authenticatedFetch } = useContext(AdminContext);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchUsers = useCallback(async(searchTerm: string) => {
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/users/searchUserByEmailOrNameDistinct`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                search: searchTerm,
                page: 0,
                resultsPerPage: 25
            })
        });

        const response = await authenticatedFetch(request);
        setLoading(false);
        if (response === null || !response.ok) {
            alert("There was an error.");
            return;
        }
        const responseJson = await response.json();
        setUserList(responseJson.results);
    }, [authenticatedFetch])


    useEffect(() => {
        fetchUsers(searchTerm);
    }, []);

    return [userList, loading, fetchUsers] as const;
}
