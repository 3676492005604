import React, {useContext, useEffect, useState} from "react";
import "./MasqueradeForm.scss";
import {AdminContext} from "../../contexts/AdminContext";
import {loadConfig} from "../../services/ConfigService";

interface MasqueradeResponseBody {
    masqueradeToken: string
    masqueradeTokenExpiresAtEpochSeconds: number
}

interface MasqueradeProps {
    userId: number
    clubId?: number
    relationshipTypeId?: number
}

export default function MasqueradeForm(props : MasqueradeProps): JSX.Element {
    const {authenticatedFetch} = useContext(AdminContext);

    const [masqueradeResult, setMasqueradeResult] = useState("");
    const [masqueradeUrl, setMasqueradeUrl] = useState<string>();
    const [masqueradeTokenExpiresAtEpochSeconds, setMasqueradeTokenExpiresAtEpochSeconds] = useState<number>();
    const [masqueradeTokenExpiresMessage, setMasqueradeTokenExpiresMessage] = useState("");

    const onExpired = () => {
        setMasqueradeUrl(undefined);
        setMasqueradeTokenExpiresAtEpochSeconds(undefined);
        setMasqueradeTokenExpiresMessage("Token expired!");
    };

    // Clear the result when the masquerade token expires.
    useEffect(
        () => {
            if (masqueradeTokenExpiresAtEpochSeconds === undefined) {
                return;
            }

            const expiresInMillis = () =>
                Math.max(0, masqueradeTokenExpiresAtEpochSeconds * 1000 - Date.now());

            const updateExpiresMessage = () => {
                const expiresInSeconds = Math.floor(expiresInMillis() / 1000);

                // TODO: Hide the link after it's clicked, indicating that it's been consumed?
                setMasqueradeTokenExpiresMessage(
                    "This link expires after it's clicked or in " + expiresInSeconds + " seconds, whichever comes first.");
            }

            updateExpiresMessage();

            const intervalId = setInterval(updateExpiresMessage, 1000);
            const timeoutId = setTimeout(onExpired, expiresInMillis());

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        },
        [masqueradeTokenExpiresAtEpochSeconds]);

    async function onSubmitMasqueradeForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setMasqueradeResult("Attempting masquerade...");

        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/masquerade`;

        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: props.userId,
                clubId: props.clubId,
                relationshipTypeId: props.relationshipTypeId
            })
        });

        const response = await authenticatedFetch(request);

        if (response === null) {
            setMasqueradeResult("Failed!  You are logged out.");
            setMasqueradeUrl(undefined);
            setMasqueradeTokenExpiresAtEpochSeconds(undefined);
            return;
        }

        if (!response.ok) {
            const responseText = await response.text();
            setMasqueradeResult(`Failed!  Response was ${response.status} ${response.statusText}:\n\n${responseText}`);
            setMasqueradeUrl(undefined);
            setMasqueradeTokenExpiresAtEpochSeconds(undefined);
            return;
        }

        const responseBody: MasqueradeResponseBody = await response.json();

        setMasqueradeResult(`Success!`);
        setMasqueradeUrl(`${config.frontendOrigin}/masquerade#token=${encodeURIComponent(responseBody.masqueradeToken)}`);
        setMasqueradeTokenExpiresAtEpochSeconds(responseBody.masqueradeTokenExpiresAtEpochSeconds);
    }

    return (
        <div className="MasqueradeForm">
            <form onSubmit={onSubmitMasqueradeForm}>
                <button type="submit">Masquerade</button>
                {masqueradeResult &&
                    <div className="MasqueradeForm_ResultWrapper">
                        <div>{masqueradeResult}</div>
                        {masqueradeUrl && (
                            <a onClick={() => onExpired()} target="_blank" rel="noopener noreferrer" href={masqueradeUrl}>
                                Start masquerade (in a new window)
                            </a>
                        )}
                        <div>{masqueradeTokenExpiresMessage}</div>
                    </div>
                }
            </form>
        </div>
    );
}
