import React from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
} from '@material-ui/core';
import { PlanOptions } from '../common/enum/club';

interface ClubDuesPlanOptionsProps {
    onChange: (selected: PlanOptions) => void;
    value: PlanOptions;
}

const ClubDuesPlanOptions = ({value, onChange, } : ClubDuesPlanOptionsProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(event.target.value));
    };

    return(
        <FormControl component="fieldset">
            <div className='club-dues-plan-options'>
                <RadioGroup
                    row defaultValue="monthly" aria-label="club-dues-plan-cadence" name="customized-radios"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value={PlanOptions.MONTHLY} control={<Radio />} label="Monthly" />
                    <FormControlLabel value={PlanOptions.ANNUAL} control={<Radio />} label="Annual" />
                </RadioGroup>
            </div>
        </FormControl>
    );
}

export default ClubDuesPlanOptions;