import React, { useContext, useEffect } from "react";
import "./NotFoundPage.scss";
import { AdminContext } from "../../contexts/AdminContext";
import { useHistory } from "react-router-dom";

export default function NotFoundPage(): JSX.Element {
    const {admin} = useContext(AdminContext);
    const history = useHistory();
    useEffect(() => {
        if (!admin) {
            history.push("/login");
        }
    }, [admin])
    return (
        <div className="NotFoundPage">
            <h1>
                Not found!
            </h1>
        </div>
    );
}
