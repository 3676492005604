import React from "react";

import { loadConfig } from "../../services/ConfigService";

function ActivityTrackerMonthlyMemberReport() {
    async function onClick() {
        const config = await loadConfig();
        let apiUrl = `${config.apiOrigin}/admin/reports/MonthlyActivityTrackerMemberReport`;
        window.location.href = apiUrl;
    }

    return <button onClick={onClick}>Export</button>;
}

export default ActivityTrackerMonthlyMemberReport;
