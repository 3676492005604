import React, {useContext} from "react";
import "./LogoutPage.scss";
import LogoutForm from "../../components/logoutForm/LogoutForm";
import {Link} from "react-router-dom";
import {AdminContext} from "../../contexts/AdminContext";

export default function LogoutPage(): JSX.Element {
    const {admin} = useContext(AdminContext);

    return (
        <div className="LogoutPage">
            <p>
                This is the logout page of the admin-only website.
            </p>
            {admin === null && (
                <p>You are currently logged out.</p>
            )}
            {admin !== null && (
                <p>You are currently logged in as <code>{admin.email}</code>.</p>
            )}
            <LogoutForm />
            <p>
                <Link to="/">Return to the home page</Link>
            </p>
        </div>
    );
}
