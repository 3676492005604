import React, {useEffect, useState} from "react";
import "./ListEventImportExportJobRecords.scss";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/AdminService";

enum EventExportDestination {
    Optum = 1
}

interface EventExportImportJobRecord {
    jobId: number
    runAt: string
    runBy: number
    destination: EventExportDestination
}

function ListEventImportExportJobRecords(): JSX.Element {

    const [jobRecords, setJobRecords] = useState<null | EventExportImportJobRecord[]>();

    useEffect(() => {

        async function loadJobRecords() {
            const config = await loadConfig();
            const apiUrl = `${config.apiOrigin}/admin/reports/getEventExportImportJobRecords`;
            const request = new Request(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const response = await authenticatedFetch(request);
            if (response === null || !response.ok) {
                console.log(response);
                alert("There was an error.");
                return;
            }
            const jobRecords = await response.json();
            setJobRecords(jobRecords);
        }
        if (jobRecords === undefined) {
            loadJobRecords();
        }
    }, [jobRecords, setJobRecords]);

    async function markImported(jobId: number) {
        if (window.confirm("Do you want to mark this export as successfully imported?")) {
            const config = await loadConfig();
            const apiUrl = `${config.apiOrigin}/admin/reports/updateEventExportImportRecords`;
            const request = new Request(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    jobId: jobId,
                    success: true
                })
            });

            const response = await authenticatedFetch(request);
            if (response === null || !response.ok) {
                console.log(response);
                alert("There was an error.");
                return;
            }

            alert("Marked!");
        }
    }

    return (<>
        <table className={"ListEventImportExportJobRecords"}>
            <thead>
            <tr>
                <td>Job Id</td>
                <td>Job Run At</td>
                <td>Job Run By</td>
                <td>Destination</td>
                <td>Action</td>
            </tr>

            </thead>
            <tbody>
            {jobRecords != null &&
            jobRecords.map(record => (
                <tr key={record.jobId}>
                    <td>{record.jobId}</td>
                    <td>{record.runAt}</td>
                    <td>{record.runBy}</td>
                    <td>{record.destination}</td>
                    <td>
                        <button onClick={() => {
                            markImported(record.jobId);
                        }}>Mark as imported
                        </button>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </>);
}

export default ListEventImportExportJobRecords;
