import React, {useContext, useState} from "react";
import "./LogoutForm.scss";
import {AdminContext} from "../../contexts/AdminContext";

export default function LogoutForm(): JSX.Element {
    const {logout, logoutAll} = useContext(AdminContext);

    const [logoutResult, setLogoutResult] = useState("");

    async function onClickLogout(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setLogoutResult("Logging out...");
        try {
            await logout();
            setLogoutResult("It worked!");
        } catch (e) {
            setLogoutResult("It failed!\n" + e.stack);
            console.error("Error", e);
        }
    }

    async function onClickLogoutAll(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setLogoutResult("Logging out on all devices...");
        try {
            await logoutAll();
            setLogoutResult("It worked!");
        } catch (e) {
            setLogoutResult("It failed!\n" + e.stack);
            console.error("Error", e);
        }
    }

    return (
        <div className="LogoutForm">
            <p>
                <button type="button" onClick={onClickLogout}>Log out</button>
            </p>
            <p>
                <button type="button" onClick={onClickLogoutAll}>Log out on all devices</button>
            </p>
            <pre>{logoutResult}</pre>
        </div>
    );
}
