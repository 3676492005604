import React from "react";
import "./LoginPage.scss";
import LoginForm from "../../components/loginForm/LoginForm";

export default function LoginPage(): JSX.Element {
    return (
        <div className="LoginPage">
            <h1>Login</h1>
            <LoginForm />
        </div>
    );
}
