import React, {useEffect} from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import JsJodaUtils from "@prinzdezibel/date-io-js-joda";
import {LocalDate} from "@js-joda/core";
import {Locale} from "@js-joda/locale_en-us";
import {
    Checkbox,
    FormControl,
    FormLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import {labelForTimeZone, SupportedTimeZone} from "../../util/SupportedTimeZone";
import {useFormState} from "react-use-form-state";
import "./UserReportFilters.scss";

interface UserReportFilterProperties {
    //function that exposes the filter form state. See the react-use-form-state docs for the structure of this object.
    onChange: Function
}

export const initialState = {
    dateRangeStart: LocalDate.now().minusMonths(1).toString(),
    dateRangeEnd: LocalDate.now().toString(),
    dateRangeTimeZone: SupportedTimeZone.EASTERN,
    salesforceExport: false
}

export default function UserReportFilters(props : UserReportFilterProperties) {

    const [formState, {raw, checkbox}] = useFormState(initialState, {
        onChange(e, stateValues, nextStateValues) {
            props.onChange(nextStateValues);
        }
    });
    const onChange = props.onChange;

    function parse(dateValue:string) {
        try {
            return LocalDate.parse(dateValue);
        } catch(e) {
            return "";
        }
    }

    useEffect(() => {
        //propagate the initial form state up to any subscribing components
        onChange(formState.values);
    }, [onChange, formState.values]);

    useEffect(() => {
        if (formState.values.salesforceExport) {
            const yesterday = LocalDate.now().minusDays(1).toString()
            formState.setField("dateRangeStart", yesterday);
            formState.setField("dateRangeEnd", yesterday);
        }
    }, [formState.values.salesforceExport]);

    return (<div className="UserReportFilter_Wrapper">
      <h4>User Registered At Range</h4>
      <div className="UserReportFilter_DateFilterWrapper">
          <MuiPickersUtilsProvider utils={JsJodaUtils(LocalDate)} locale={Locale.US}>
              <div className="UserReportFilter_DateInputWrapper">
                  <FormControl>
                      <FormLabel>Start</FormLabel>
                      <KeyboardDatePicker className={"UserDataDateInput"}
                                          {...raw({
                                              name: 'dateRangeStart',
                                              touchOnChange: true,
                                              onChange: date => date.toString()
                                          })}
                                          value={parse(formState.values.dateRangeStart)}
                                          variant="inline"
                                          format="MM/dd/yyyy"/>
                  </FormControl>
              </div>
              <div className="UserReportFilter_DateInputWrapper">
                  <FormControl>
                      <FormLabel>End</FormLabel>
                      <KeyboardDatePicker
                          {...raw({
                              name: 'dateRangeEnd',
                              touchOnChange: true,
                              onChange: date => date.toString()
                          })}
                          value={parse(formState.values.dateRangeEnd)}
                          variant="inline"
                          format="MM/dd/yyyy"/>
                  </FormControl>
              </div>
              <FormControl>
                  <Select required
                          {...raw({
                              name: 'dateRangeTimeZone',
                              touchOnChange: true,
                              onChange: timeZone => timeZone.target.value
                          })}
                          variant="outlined">
                      {Object.values(SupportedTimeZone).map(supportedTimeZone => (
                          <MenuItem key={supportedTimeZone}
                                    value={supportedTimeZone}>
                              {labelForTimeZone(supportedTimeZone)}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <div className={"UserReportFilter_SalesforceCheckboxWrapper"}>
                  <FormControl>
                      <label>Export for Salesforce</label>
                      <Checkbox required
                              {...checkbox({
                                  name: 'salesforceExport',
                                  touchOnChange: true,
                                  onChange: checked => checked.target.value
                              })}/>
                  </FormControl>
              </div>
          </MuiPickersUtilsProvider>
      </div>
  </div>)
}
