import React from "react";
import "./Copyright.scss";

export default function Copyright(): JSX.Element {
  return (
    <div className={`Copyright`}>
      &copy;2024 Element3 Health, Inc. All Rights Reserved.{" "}
    </div>
  );
}
