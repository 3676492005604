import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from "react";
import { useFormState } from 'react-use-form-state';

import { AdminContext } from "../../contexts/AdminContext";
import { loadConfig } from "../../services/ConfigService";
import Paging from "../../pages/home/Paging";
import MasqueradeForm from "../masqueradeForm/MasqueradeForm";
import ViewEditTextField from "./ViewEditTextField";

import "./ManageUsersList.scss";
import {UserTypeById} from "../common/enum/club";

export interface ClubInfo {
    id: number
    urlFragment: string
    clubName: string
    relationshipType: number
}

export interface UserAdminView {
    id: number,
    email: string,
    phone: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    userType: number,
    isEmailVerified: boolean,
    isDeleted: boolean
    club: ClubInfo | null
}

export default function ManageUsersList() {
    const [userList, setUserList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const {authenticatedFetch} = useContext(AdminContext);
    const [message, setMessage] = useState("");
    const [formState, { text }] = useFormState();
    const [totalResults, setTotalResults] = useState(0);
    const [frontendOrigin, setFrontendOrigin] = useState<string | null>(null);

    useEffect(() => {
        async function loadFrontendOrigin() {
            const config = await loadConfig();
            setFrontendOrigin(config.frontendOrigin);
        }

        loadFrontendOrigin();
    }, []);

    const resultsPerPage = 25;
    const [page, setPage] = useState(0);



    interface ManageUserTableProps {
        users: Array<UserAdminView>
    }

    const disableUser = async (userId: number) => {
        setMessage("Disabling User...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/users/disableUser`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to disable user.");
            return;
        }
        const responseJson = await response.json();
        if (!responseJson.userDeleted) {
            setMessage("Failed to disable user");
        }
        setMessage("User disabled.");
        loadUserList();
    }

    const enableUser = async (userId: number) => {
        setMessage("Enabling User...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/users/enableUser`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to enable user.");
            return;
        }
        const responseJson = await response.json();
        if (!responseJson.userDeleted) {
            setMessage("Failed to enable user");
        }
        setMessage("User enabled.");
        loadUserList();
    }

    const updateEmailRequest = async (userId: number, email: string) => {
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/users/updateEmail`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId,
                email
            })
        });

        return request;
    }

     function ManageUserTable(props : ManageUserTableProps) {
        return (
            <div>
                <div className={"ManageUserList_TableWrapper"}>
                    <div className="ManageUserList_Row ManageUserList_Headers">
                        <div className="ManageUserList_GridItem">Email</div>
                        <div className="ManageUserList_GridItem">First Name</div>
                        <div className="ManageUserList_GridItem">Last Name</div>
                        <div className="ManageUserList_GridItem">Club Status</div>
                        <div className="ManageUserList_GridItem">Account Status</div>
                        <div className="ManageUserList_GridItem">Masquerade</div>
                    </div>
                    {props.users.map(user => (
                        <div key={`${user.id}-${user.club?.id}`} className="ManageUserList_Row">
                            <div className="ManageUserList_GridItem">
                                <ViewEditTextField
                                    type="email"
                                    initialValue={user.email}
                                    onSuccessSave={loadUserList}
                                    setMessage={setMessage}
                                    confirmRequest={(email: string) => updateEmailRequest(user.id, email)}/>
                            </div>
                            <div className="ManageUserList_GridItem">{user.firstName}</div>
                            <div className="ManageUserList_GridItem">{user.lastName}</div>
                            <div className="ManageUserList_GridItem ManageUserList_UserType">
                                {user.club && frontendOrigin &&
                                <>
                                  {UserTypeById[user.club.relationshipType]}
                                  <br />
                                  <a target="_blank"
                                     rel="noopener noreferrer"
                                     href={`${frontendOrigin}/club/${encodeURIComponent(user.club.urlFragment)}`}>{user.club.clubName}</a>
                                </>
                                }
                            </div>
                            <div className="ManageUserList_GridItem ManageUserList_AccountStatus">
                                {user.isDeleted && <div className="ManageUserList_AccountStatusText Disabled">Disabled</div>}
                                {!user.isDeleted && <div className="ManageUserList_AccountStatusText Enabled">Enabled</div>}
                                {user.isDeleted ?
                                    <button onClick={() => enableUser(user.id)}><b>Enable</b></button>
                                    :
                                    <button onClick={() => disableUser(user.id)}><b>Disable</b></button>
                                }
                            </div>
                            <div className="ManageUserList_GridItem"><MasqueradeForm userId={user.id} clubId={user.club?.id} relationshipTypeId={user.club?.relationshipType}/></div>
                        </div>
                    ))}
                </div>
                {totalResults.toLocaleString()} matching {totalResults === 1 ? "user" : "users"}.
                {totalResults > resultsPerPage &&
                <Paging pageSize={resultsPerPage}
                        numberResults={totalResults}
                        currentPage={page}
                        onSelect={(newPage: number) => setPage(newPage)}/>}
            </div>
        )
    }

    const loadUserList = useCallback(async() => {
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/users/searchUserByEmailOrName`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                search: formState.values.search,
                page,
                resultsPerPage
            })
        });

        const response = await authenticatedFetch(request);
        setLoadingUsers(false);
        if (response === null || !response.ok) {
            setMessage("Failed to load users.");
            return;
        }
        const responseJson = await response.json();
        setUserList(responseJson.results);
        setTotalResults(responseJson.totalResults);
    }, [page, authenticatedFetch, formState.values.search])

    useEffect(() => {
        loadUserList();
    }, [loadUserList]);

    let content;
    if (loadingUsers) {
        content = <div>Loading Users...</div>;
    } else if (userList.length === 0) {
        content =  <div>No users.</div>;
    } else {
        content=<ManageUserTable users={userList}/>
    }
    return (
        <div>
            <input {...text({
                name: 'search',
                onChange: (event) => {
                    setPage(0);
                    loadUserList();
                }
            })} placeholder={"Search by email/name"}/>
            <div>
                {message && <div className="ManageUserList_Message">{message}</div>}
                {content}
            </div>
        </div>);
}