import React, {useState} from "react";
import {loadConfig} from "../../services/ConfigService";
import './UserReport.scss';
import UserReportFilters from "./UserReportFilters";

// This functionality was originally called the "User Report", and then it was
// renamed to the "Member Registrations Report".  Admin-facing text (header
// text, file names) should use the new name, but code (function names, URLs)
// may refer to the old name.

function UserReport(): JSX.Element {
    async function onClick() {
        const config = await loadConfig();
        let apiUrl = `${config.apiOrigin}/admin/reports/userReport`;
        apiUrl += `?userFilterStart=${filterState.dateRangeStart}`;
        apiUrl += `&userFilterEnd=${filterState.dateRangeEnd}`;
        apiUrl += `&userFilterTimeZone=${filterState.dateRangeTimeZone}`;
        apiUrl += `&salesforceExport=${filterState.salesforceExport}`;
        window.location.href = apiUrl;
    }

    const [filterState, setFilterState] = useState();

    return (
        <div className="UserReport">
            <div className="UserReport_Filters">
                <h3>Filters</h3>
                <UserReportFilters onChange={(state: object) => setFilterState(state)}/>
            </div>
            <button onClick={onClick}>Export</button>
        </div>
    );
}

export default UserReport;
