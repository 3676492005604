import React from "react";

import "./Header.scss";
import logo from "../../assets/logo.svg";
import {Link, withRouter} from "react-router-dom";
import MyAccount from "./MyAccount";

interface HeaderProps {
}

function Header(props: HeaderProps): JSX.Element {

    return (
        <div className={`Header`}>
            <Link to={"/"}><img className="Logo" src={logo} alt="logo"/></Link>
            <div className={`Title`}>Admin</div>
            <MyAccount/>
        </div>
    );
}

export default withRouter(Header);