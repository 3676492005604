import React from "react";
import "./Paging.scss";

interface PagingProps {
  numberResults: number,
  currentPage: number,
  onSelect: Function,
  pageSize: number
}

export default function Paging(props : PagingProps) {
    const totalPages = Math.ceil(props.numberResults / props.pageSize);
    return (<div className="Paging_Wrapper">
        <div>Showing {props.pageSize} {props.pageSize === 1 ? "result" : "results"}  per page.</div>
        <div>
          {props.currentPage - 2 > 0 &&
            <div onClick={() => props.onSelect(props.currentPage - 2)} className="Paging_Page">{props.currentPage - 1}</div>}
          {props.currentPage > 0 &&
            <div onClick={() => props.onSelect(props.currentPage - 1)} className="Paging_Page">{props.currentPage}</div>}
            <div onClick={() => props.onSelect(props.currentPage)} className="Paging_Page selected">{props.currentPage + 1}</div>
          {props.currentPage + 2 <= totalPages &&
            <div onClick={() => props.onSelect(props.currentPage + 1)} className="Paging_Page">{props.currentPage + 2}</div> }
          {props.currentPage + 3 <= totalPages &&
        <div onClick={() => props.onSelect(props.currentPage + 2)} className="Paging_Page">{props.currentPage + 3}</div>}
      </div>
  </div>);
}