import {loadConfig} from "./ConfigService";
import i18n from "i18next";

interface LoginResponseBody {
    sessionExpiresAtEpochSeconds: number
}

export interface Admin {
    email: string
    sessionExpiresAtEpochSeconds: number
}

export function isSessionExpired(admin: Admin): boolean {
    return admin.sessionExpiresAtEpochSeconds * 1000 <= Date.now();
}

const ADMIN_STORAGE_KEY = "ClubCalendarAdmin";

export enum LocationOwnerType {
    User = 1,
    Club = 2,
    Event = 3
}

function checkResponseOk(response: Response) {
    if (!response.ok) {
        throw new Error(`Non-ok response ${response.status} ${response.statusText} from URL ${response.url}`);
    }
}

function getLanguageSelection() {
    return i18n.resolvedLanguage;
}

export function getAdmin(): Admin | null {
    const serialized = localStorage.getItem(ADMIN_STORAGE_KEY);
    return (serialized == null) ? null : JSON.parse(serialized);
}

function setAdmin(admin: Admin) {
    const serialized = JSON.stringify(admin);
    localStorage.setItem(ADMIN_STORAGE_KEY, serialized);
}

function clearAdmin() {
    localStorage.removeItem(ADMIN_STORAGE_KEY);
}

export async function logout(): Promise<void> {
    clearAdmin();

    const config = await loadConfig();
    const url = `${config.apiOrigin}/admin/auth/logout`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include"
    });

    const response = await fetch(request);

    checkResponseOk(response);
}

export async function logoutAll(): Promise<void> {
    clearAdmin();

    const config = await loadConfig();
    const url = `${config.apiOrigin}/admin/auth/logoutall`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include"
    });

    const response = await fetch(request);

    checkResponseOk(response);
}

/**
 * Returns the admin with the specified email and password if those credentials are accepted by the API.  Returns `null`
 * if either one is incorrect.  Throws an error if the API can't be reached or there is an unexpected HTTP response from
 * the API.
 *
 * @param email the admin's email address
 * @param password the admin's plaintext password
 */
export async function login(email: string, password: string): Promise<Admin | null> {
    const config = await loadConfig();
    const url = `${config.apiOrigin}/admin/auth/login`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email,
            password
        })
    });

    const response = await fetch(request);

    if (response.status === 401) {
        return null;
    }

    checkResponseOk(response);

    const responseBody: LoginResponseBody = await response.json();

    const admin: Admin = {
        email,
        sessionExpiresAtEpochSeconds: responseBody.sessionExpiresAtEpochSeconds
    };

    setAdmin(admin);

    return admin;
}

// TODO: This returns null when the admin needs to log in manually.  Is there a better way to represent that case in the
//       return type?
export async function authenticatedFetch(request: Request): Promise<Response | null> {
    let admin = getAdmin();

    if (admin === null || isSessionExpired(admin)) {
        return null;
    }

    const combinedHeaders = new Headers();
    combinedHeaders.append('Accept-Language', getLanguageSelection());
    for (const [ headerName, headerValue ] of Array.from(request.headers.entries())) {
        combinedHeaders.append(headerName, headerValue);
    }

    const authenticatedRequest = new Request(request, {
        credentials: "include",
        headers: combinedHeaders
    });

    return await fetch(authenticatedRequest);
}

// FIXME: This is example usage of `authenticatedFetch` and should be deleted eventually.
export async function test() {
    const config = await loadConfig();
    const url = `${config.apiOrigin}/admin/auth/test`;
    const request = new Request(url);
    const response = await authenticatedFetch(request);

    if (response === null) {
        throw new Error("Login required!");
    }

    checkResponseOk(response);
}
