export interface AdminConfig {
    apiOrigin: string
    frontendOrigin: string
}

function checkResponseOk(response: Response) {
    if (!response.ok) {
        throw new Error(`Non-ok response ${response.status} ${response.statusText} from URL ${response.url}`);
    }
}

function checkKeyIsPresent<T extends object, K extends keyof T>(object: T, key: K) : T[K] {
    if (!object.hasOwnProperty(key)) {
        throw new Error(`Missing required key '${key}'`);
    }
    return object[key];
}

let cachedConfig: AdminConfig;

export async function loadConfig(): Promise<AdminConfig> {
    if (cachedConfig !== undefined) {
        return cachedConfig;
    }

    // The config file is in the "public" directory, not the "src" directory, so that it can be replaced at runtime.  If
    // we used the "src" directory instead, configuration would have to occur at build time.
    const url = process.env.PUBLIC_URL + "/config/config.json";
    const response = await fetch(url);
    checkResponseOk(response);

    const config: AdminConfig = await response.json();

    // TODO: There is probably a more elegant way to validate the shape of the config, but the
    //       solutions we found in initial searches were not appealing.  If we do find a good
    //       solution, perhaps we can also use it for verifying the response bodies from our API and
    //       for verifying serialized objects in local storage.
    checkKeyIsPresent(config, "apiOrigin");
    checkKeyIsPresent(config, "frontendOrigin");

    cachedConfig = config;
    return config;
}
