import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import './i18n';
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import {AdminContextProvider} from "./contexts/AdminContext";
import Layout from "./components/layout/Layout";

ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback="">
        <AdminContextProvider>
            <BrowserRouter>
                <Layout/>
            </BrowserRouter>
        </AdminContextProvider>
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
