import React, { useState, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ClubVerificationStatus } from '../common/enum/club';
import { withStyles } from '@material-ui/core/styles';
import { green, red, blue } from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';


interface ManageStatusProps {
    status: ClubVerificationStatus;
    onUpdated: (clubVerificationStatus: ClubVerificationStatus) => void;
}

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const RedRadio = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const BlueRadio = withStyles({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props}/>);

export default function FormControlLabelPlacement(props: ManageStatusProps) {
    const status = props.status;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onUpdated(parseInt(event.target.value));
    };

    return (
        <RadioGroup row aria-label="Club Verification Status" name="verification-status" defaultValue={status}>
            <FormControlLabel
                value="female"
                control={
                    <GreenRadio
                        checked={status === ClubVerificationStatus.VALID}
                        onChange={handleChange}
                        value={ClubVerificationStatus.VALID}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Valid' }}
                    />
                }
                label="Valid"
            />

            <FormControlLabel
                value="female"
                control={
                    <RedRadio
                        checked={status === ClubVerificationStatus.INVALID}
                        onChange={handleChange}
                        value={ClubVerificationStatus.INVALID}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Invalid' }}
                    />
                }
                label="Invalid"
            />

            <FormControlLabel
                value="female"
                control={
                    <BlueRadio
                        checked={status === ClubVerificationStatus.UNVERIFIED}
                        onChange={handleChange}
                        value={ClubVerificationStatus.UNVERIFIED}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Verified' }}
                    />
                }
                label="Unverified"
            />
        </RadioGroup>
    );
}