import React, {useState, useEffect} from "react";
import * as AdminService from "../services/AdminService";
import {Admin} from "../services/AdminService";

export interface AdminState {
    admin: Admin | null,
    login: (email: string, password: string) => Promise<boolean>,
    logout: () => Promise<void>,
    logoutAll: () => Promise<void>,
    authenticatedFetch: (request: Request) => Promise<Response | null>
}

const initialAdminState: AdminState = {
    admin: null,
    login: () => Promise.resolve(false),
    logout: () => Promise.resolve(),
    logoutAll: () => Promise.resolve(),
    authenticatedFetch: () => Promise.resolve(null)
};

/**
 * Exposes the current admin to a JSX element.
 *
 * Example usage:
 *
 * ```
 * function MyCustomElement(): JSX.Element {
 *     const {admin} = useContext(AdminContext);
 *
 *     return (
 *         <div>
 *             {admin !== null && (
 *                 <p>You're logged in as {admin.email}</p>
 *             )}
 *             {admin === null && (
 *                 <p>You're logged out</p>
 *             )}
 *         </div>
 *     );
 * }
 * ```
 */
export const AdminContext: React.Context<AdminState> =
    React.createContext(initialAdminState);

/**
 * Allows child elements to use {@link AdminContext}.  This should be applied
 * at the application level.
 */
export function AdminContextProvider({ children }: React.PropsWithChildren<React.ReactNode>) {
    const [admin, setAdmin] = useState<Admin | null>(AdminService.getAdmin());

    async function login(email: string, password: string): Promise<boolean> {
        const admin = await AdminService.login(email, password);
        setAdmin(admin);
        return admin !== null;
    }

    async function logout() {
        await AdminService.logout();
        setAdmin(null);
    }

    async function logoutAll() {
        await AdminService.logoutAll();
        setAdmin(null);
    }

    async function authenticatedFetch(request: Request): Promise<Response | null> {
        return await AdminService.authenticatedFetch(request);
    }

    // Clear the admin when their session expires, since having an expired
    // session means they are effectively logged out.
    useEffect(
        () => {
            if (admin === null) {
                return;
            }

            const expiresInMillis =
                admin.sessionExpiresAtEpochSeconds * 1000 - Date.now();

            const timeoutId =
                setTimeout(
                    () => {
                        setAdmin(null);
                    },
                    expiresInMillis);

            return () => {
                clearTimeout(timeoutId);
            };
        },
        [admin]);

    const currentAdminState: AdminState = {
        admin: admin,
        login,
        logout,
        logoutAll,
        authenticatedFetch
    };

    return (
        <AdminContext.Provider
            value={currentAdminState}
            children={children} />
    );
}
