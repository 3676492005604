import React, {
    ChangeEvent,
    useContext,
    useState
} from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import { AdminContext } from "../../contexts/AdminContext";
import { loadConfig } from "../../services/ConfigService";
import { useAllPasions } from "../../hooks/useAllPassions";

import "./ManagePassionsList.scss";

interface UpdatePassionImageUrlRequestBody {
    passionId: number
    imageUrl: string
}

interface UpdatePassionCategoryRequestBody {
    passionId: number
    category: string
}

interface ManagePassionRowProps {
    id: number
    passionName: string
    imageUrl: string
    category: string
    updateImageUrl: (imageUrl: string) => Promise<void>
    updateCategory: (category: string) => Promise<void>
}

function ManagePassionRow(props: ManagePassionRowProps): JSX.Element {
    const {id, passionName} = props;

    const [imageUrl, setImageUrl] = useState(props.imageUrl);
    const [category, setCategory] = useState(props.category);

    const [updatingImageUrl, setUpdatingImageUrl] = useState(false);
    const [updatingCategory, setUpdatingCategory] = useState(false);

    return (
        <tr>
            <td>{id}</td>
            <td>{passionName}</td>
            <td style={{
                // Allocate more horizontal space for the image URLs, which are long.
                width: "50%"
            }}>
                <form>
                    <TextField
                        value={imageUrl}
                        onChange={
                            (event: ChangeEvent<HTMLInputElement>) => {
                                setImageUrl(event.target.value);
                            }
                        }
                    />
                    <Button
                        className={"ManagePassionRow_UpdateButton"}
                        disabled={updatingImageUrl || imageUrl === props.imageUrl}
                        onClick={
                            async () => {
                                setUpdatingImageUrl(true);
                                await props.updateImageUrl(imageUrl);
                                setUpdatingImageUrl(false);
                            }
                        }
                    >
                        Update
                        <CircularProgress
                            size={"2em"}
                            className={`ManagePassionRow_UpdateProgress ${updatingImageUrl ? "Updating" : "NotUpdating"}`}
                        />
                    </Button>
                </form>
            </td>
            <td>
                <form>
                    <TextField
                        value={category}
                        onChange={
                            (event: ChangeEvent<HTMLInputElement>) => {
                                setCategory(event.target.value);
                            }
                        }
                    />
                    <Button
                        className={"ManagePassionRow_UpdateButton"}
                        disabled={updatingCategory || category === props.category}
                        onClick={
                            async () => {
                                setUpdatingCategory(true);
                                await props.updateCategory(category);
                                setUpdatingCategory(false);
                            }
                        }
                    >
                        Update
                        <CircularProgress
                            size={"2em"}
                            className={`ManagePassionRow_UpdateProgress ${updatingCategory ? "Updating" : "NotUpdating"}`}
                        />
                    </Button>
                </form>
            </td>
        </tr>
    );
}

export default function ManagePassionsList(): JSX.Element {
    const {authenticatedFetch} = useContext(AdminContext);

    const [allPassions, loadPassions] = useAllPasions();

    const updatePassionImageUrl = async (passionId: number, imageUrl: string) => {
        const config = await loadConfig();

        const requestBody: UpdatePassionImageUrlRequestBody = {
            passionId,
            imageUrl
        }

        const request = new Request(`${config.apiOrigin}/admin/passions/updatePassionImageUrl`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody)
        });

        const response = await authenticatedFetch(request);

        if (response === null || !response.ok) {
            alert("There was an error.");
            return;
        }

        loadPassions();
    };

    const updatePassionCategory = async (passionId: number, category: string) => {
        const config = await loadConfig();

        const requestBody: UpdatePassionCategoryRequestBody = {
            passionId,
            category
        }

        const request = new Request(`${config.apiOrigin}/admin/passions/updatePassionCategory`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody)
        });

        const response = await authenticatedFetch(request);

        if (response === null || !response.ok) {
            alert("There was an error.");
            return;
        }

        loadPassions();
    };

    return (
        <div className="ManagePassionsList">
            <table>
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Passion Name</td>
                    <td>Image URL</td>
                    <td>Category</td>
                </tr>
                </thead>
                <tbody>
                {allPassions.map(passion =>
                    <ManagePassionRow
                        key={passion.id}
                        id={passion.id}
                        passionName={passion.passionName}
                        imageUrl={passion.imageUrl}
                        category={passion.category}
                        updateImageUrl={
                            async imageUrl => {
                                await updatePassionImageUrl(passion.id, imageUrl);
                            }
                        }
                        updateCategory={
                            async category => {
                                await updatePassionCategory(passion.id, category);
                            }
                        }
                    />
                )}
                </tbody>
            </table>
        </div>
    );
}
