import React, {useState} from "react";
import {loadConfig} from "../../services/ConfigService";
import EventReportFilters from "./EventReportFilters";

interface AttendanceDataExportProps {
    isVirtual: boolean;
}

function AttendanceDataExport(props: AttendanceDataExportProps): JSX.Element {

    let isVirtual = props.isVirtual;

    async function onClick() {
        const config = await loadConfig();
        let apiUrl = `${config.apiOrigin}/admin/reports/exportAttendanceDataForOptum`;
        apiUrl += `?eventFilterStart=${filterState.dateRangeStart}`;
        apiUrl += `&eventFilterEnd=${filterState.dateRangeEnd}`;
        apiUrl += `&eventFilterTimeZone=${filterState.dateRangeTimeZone}`;
        apiUrl += `&isVirtual=${isVirtual}`;
        window.location.href = apiUrl;
    }

    const [filterState, setFilterState] = useState();

    return (<>
        <div className="AttendanceDataExport">
            <div className="AttendanceDataExport_Filters">
                <h3>Filters</h3>
                <EventReportFilters onChange={(state: object) => setFilterState(state)}/>
            </div>
            <button onClick={onClick}>Export</button>
        </div>
    </>);
}

export default AttendanceDataExport;
