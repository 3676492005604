import React, {useEffect, useRef, useState} from "react";
import {Button, CircularProgress, FormControl, MenuItem, TextField, Grid} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useSearchDistinctUsersByEmailOrName} from "../../hooks/useSearchDistinctUsersByEmailOrName";
import "./ClubMemberManagement.scss";
import {UserTypeById} from "../common/enum/club";
import {authenticatedFetch} from "../../services/AdminService";
import {loadConfig} from "../../services/ConfigService";

interface ClubMemberManagementProps {
    clubId: number | null;
}

interface UserOption {
    email: string;
    firstName: string;
    lastName: string;
    id: number;
}

export default function ClubMemberManagement(props: ClubMemberManagementProps) {
    const [message, setMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [errored, setErrored] = useState(false);
    const [open, setOpen] = useState(false);

    const timerId = useRef(0);

    const [autoCompleteValue, setAutoCompleteValue] = useState<UserOption | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [userList, searching, fetchUsers] = useSearchDistinctUsersByEmailOrName(searchTerm);
    const [userClubRelationshipTypeId, setUserClubRelationshipTypeId] = useState<number | null>(1);

    const submitEnabled = () => {
        return autoCompleteValue !== null && userClubRelationshipTypeId !== null;
    }

    const onSubmit = async() => {
        setSaving(true);
        const body = {
            clubId: props.clubId,
            userId: autoCompleteValue?.id,
            userClubRelationshipTypeId: userClubRelationshipTypeId
        }
        setMessage(null);
        setErrored(false);

        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/AssignUserToClub`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const response = await authenticatedFetch(request);
        setSaving(false);
        if (response === null || !response.ok) {
            setMessage(`Failed to update user's role.`);
            setErrored(true);
            return;
        }
        setSearchTerm('');
        setAutoCompleteValue(null);
        setUserClubRelationshipTypeId(1);
        setMessage(`User's role updated successfully.`);
    }

    const fetchUsersDebounced = async () => {
        await fetchUsers(searchTerm);
    }

    const debouncedRequest = () => {
        if (timerId.current) clearTimeout(timerId.current);
        timerId.current = Number(setTimeout(fetchUsersDebounced, 1000));
    }

    useEffect(() => {
        debouncedRequest();
    }, [searchTerm]);

    return (
        <div className={"ClubMemberManagement_Form"}>
            <hr />
            <p className="ClubMemberManagement_Form__title">Member Management</p>
            <Grid container spacing={1} direction="row" alignItems="flex-start">
                <Grid item xs={8}>
                    <div className={"ClubMemberManagement_Autocomplete"}>
                        <FormControl fullWidth>
                            <Autocomplete
                                open={open}
                                style={{width: "100%"}}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setSearchTerm(newInputValue);
                                }}
                                onChange={(event, value) => {
                                    setAutoCompleteValue(value);
                                }}
                                value={autoCompleteValue}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option: UserOption) => option.email + ' ' + option.firstName + ' ' + option.lastName}
                                renderOption={(option: UserOption) => {
                                    return (
                                        <>
                                            <div>
                                                <div>
                                                    {option.email}
                                                </div>
                                                <div>&nbsp;&nbsp;({option.firstName} {option.lastName})</div>
                                            </div>
                                        </>
                                    );
                                }}
                                options={userList}
                                loading={searching}
                                disabled={saving}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className='ClubMemberManagement_Form__select'
                                        variant="outlined"
                                        margin={"dense"}
                                        InputLabelProps={{
                                            htmlFor: 'userClubRelationshipTypeId',
                                        }}
                                        label='Email or Name'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {searching && <CircularProgress color="inherit" size={20}/>}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField
                            name={"userClubRelationshipTypeId"}
                            className='ClubMemberManagement_Form__select'
                            margin={"dense"}
                            variant="outlined"
                            InputLabelProps={{
                                htmlFor: 'userClubRelationshipTypeId',
                            }}
                            label='Club Role'
                            select={true}
                            classes={{ root: 'ClubMemberManagement_Form__select' }}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'ClubMemberManagement_Form__field_error'
                                }
                            }}
                            InputProps={{
                                id: "userClubRelationshipTypeId"
                            }}
                            size='small'
                            value={userClubRelationshipTypeId}
                            onChange={(event) => {
                                setUserClubRelationshipTypeId(Number(event.target.value));
                            }}
                        >
                            {Object.entries(UserTypeById).map(([key, value]) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                >
                                    {value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                {message &&
                    <Grid item xs={12}>
                        <div className={`ClubMemberManagement_Form__message${errored ? ` errored`: `` }`}>
                            {message}
                        </div>
                    </Grid>
                }
            </Grid>
            <div className='ClubMemberManagement_Form__button-wrapper'>
                <Button
                    onClick={onSubmit}
                    className="ClubMemberManagement_Form__button"
                    variant="contained"
                    classes={{
                        label: 'ClubMemberManagement_Form__button-label',
                        disabled: 'ClubMemberManagement_Form__button-disabled'
                    }}
                    disabled={!submitEnabled() && !saving}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}







