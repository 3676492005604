import { ClubFormState, getPhoneUnmask, LocationState } from "./ClubForm";

const POSTAL_CODE_REGEX = /^[0-9]{5}$/;
export const PHONE_REGEX = /^[0-9]{10}$/;

// Email validation from https://stackoverflow.com/a/7786283
const validEmailRegex =
  /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+/;
export const validEmailRegexText = validEmailRegex.source;
export function isValidEmail(email: string): boolean {
  return validEmailRegex.test(email);
}

const getElementConfig = (
  formState: any
): Record<
  keyof ClubFormState,
  {
    name: keyof ClubFormState;
    validate?: (value: string) => string | void;
    validateOnBlur?: boolean;
    onBlur?: (e: React.FocusEvent) => void;
  }
> => ({
  clubName: {
    name: "clubName",
    validate: (value: string) => {
      if (!value.trim()) {
        return "Club name is required.";
      }
    },
    validateOnBlur: true,
  },
  type: {
    name: "type",
    validate: (value: string) => {
      if (!value) {
        return "Type is required";
      }
    },
    validateOnBlur: true,
  },
  description: {
    name: "description",
    validate: (value: string) => {
      if (!value.trim()) {
        return "Description is required";
      }
    },
    validateOnBlur: true,
  },
  zipCode: {
    name: "zipCode",
    validate: (value: string) => {
      if (
        !value.match(POSTAL_CODE_REGEX) &&
        (formState.values.location === LocationState.Offline ||
          formState.values.location === LocationState.Both)
      ) {
        return "Zip code should be valid";
      }
    },
    validateOnBlur: true,
    onBlur: (e: React.FocusEvent) => {
      const target = e.target as HTMLInputElement;
      if (!target.value) {
        formState.setFieldError("zipCode", "Zip code should be valid");
      }
    },
  },
  passion: {
    name: "passion",
    validate: (value: string) => {
      if (!value) {
        return "Club passion is required";
      }
    },
    validateOnBlur: true,
  },
  timeZone: {
    name: "timeZone",
    validate: (value: string) => {
      if (!value.trim()) {
        return "Time zone is required";
      }
    },
    validateOnBlur: true,
  },
  customerServicePhone: {
    name: "customerServicePhone",
    validate: (value: string) => {
      const formattedValue = getPhoneUnmask(value);
      if (formattedValue.trim() && !formattedValue.match(PHONE_REGEX)) {
        return "Enter a 10 digit phone number.";
      }
    },
    validateOnBlur: true,
  },
  customerServiceEmail: {
    name: "customerServiceEmail",
    validate: (value: string) => {
      if (value.trim() && !isValidEmail(value)) {
        return "Invalid Email Address";
      }
    },
    validateOnBlur: true,
  },
  city: {
    name: "city",
  },
  state: {
    name: "state",
    validate: (value: string) => {
      if (!value) {
        return "State is required";
      }
    },
    validateOnBlur: true,
  },
  location: {
    name: "location",
  },
  clubStatus: {
    name: "clubStatus",
  },
  customEnrollmentFlow: {
    name: "customEnrollmentFlow",
  },
  eventsPortalEnabled: {
    name: "eventsPortalEnabled",
  },
  igniteEnabled: {
    name: "igniteEnabled",
  },
  hostsVirtualEvents: {
    name: "hostsVirtualEvents",
  },
  hostsInPersonEvents: {
    name: "hostsInPersonEvents",
  },
  welcomeEmailEnabled: {
    name: "welcomeEmailEnabled",
  },
  emailVerificationConfirmationEmailEnabled: {
    name: "emailVerificationConfirmationEmailEnabled",
  },
  inDirectory: {
    name: "inDirectory",
  },
  inFinder: {
    name: "inFinder",
  },
  inGroupworks: {
    name: "inGroupworks",
  },
  phone: {
    name: "phone",
    validate: (value: string) => {
      const formattedValue = getPhoneUnmask(value);
      if (formattedValue.trim() && !formattedValue.match(PHONE_REGEX)) {
        return "Enter a 10 digit phone number.";
      }
    },
    validateOnBlur: true,
  },
  email: {
    name: "email",
    validate: (value: string) => {
      if (value.trim() && !isValidEmail(value)) {
        return "Invalid Email Address";
      }
    },
    validateOnBlur: true,
  },
  clubPlan: {
    name: "clubPlan",
  },
  trackingInPersonActivitiesEnabled: {
    name: "trackingInPersonActivitiesEnabled",
  },
  trackingOnlineActivitiesEnabled: {
    name: "trackingOnlineActivitiesEnabled",
  },
  verificationStatus: {
    name: "verificationStatus",
  },
});

export { getElementConfig };
