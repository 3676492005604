export enum ClubStatus {
    ACTIVE = 1,
    INACTIVE = 2
}

export enum ClubType {
    CLUB = 1,
    ASSOCIATION = 2
}

export const UserTypeById: {[key: number]: string }  = {
    0: "Member",
    1: "Club Leader",
    2: "Deputy Club Leader"
}
export enum ClubVerificationStatus {
    VALID = 1,
    INVALID = 2,
    UNVERIFIED = 3
}

export enum PlanOptions {
    MONTHLY = 1,
    ANNUAL = 2
}