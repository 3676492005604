import React from "react";
import "./RegeocodeLocations.scss";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch, LocationOwnerType} from "../../services/AdminService";

function RegeocodeLocations(): JSX.Element {

    async function onClickUsers() {
        await go(LocationOwnerType.User);
    }

    async function onClickClubs() {
        await go(LocationOwnerType.Club);
    }

    async function onClickEvents() {
        await go(LocationOwnerType.Event);
    }

    async function go(ownerType: LocationOwnerType) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Do you really want to do this?")) {
            const config = await loadConfig();
            const apiUrl = `${config.apiOrigin}/admin/utility/geocodeAllLocations?ownerType=` + ownerType;
            const request = new Request(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const response = await authenticatedFetch(request);
            if (response === null || !response.ok) {
                alert("There was an error.");
                return;
            }

            alert(ownerType + " Locations queued for re-geocoding.");
        }
    }

    return (<>
        <div className={"RegeocodeLocations"}>
            <button onClick={onClickEvents}>Re-Geocode all events</button>
            <button onClick={onClickUsers}>Re-Geocode all users</button>
            <button onClick={onClickClubs}>Re-Geocode all clubs</button>
        </div>
    </>);
}

export default RegeocodeLocations;