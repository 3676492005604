import React, { useEffect, useRef, useState } from "react";
import { useFormState } from "react-use-form-state";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import {
    MenuItem,
    Switch,
    TextField,
    Button,
    Radio,
} from "@material-ui/core";

import { getElementConfig } from './clubFormConfig'
import { SupportedTimeZone, states } from "../../util/ClubUtils";
import { useAllPasions } from "../../hooks/useAllPassions";
import { loadConfig } from "../../services/ConfigService";
import { authenticatedFetch } from "../../services/AdminService";

import "./ClubForm.scss";
import { ClubStatus, ClubType, ClubVerificationStatus, PlanOptions } from "../common/enum/club";
import ManageStatus from "../home/ManageStatus";
import Loading from "../common/Loading";
import ClubDuesPlanOptions from "./ClubDuesPlanOptions";

const TYPE_OPTIONS = [
    { label: 'Club', value: ClubType.CLUB },
    { label: 'Association', value: ClubType.ASSOCIATION }
];

export enum LocationState {
    Online = 'Online',
    Offline = 'Offline',
    Both = 'Both',
}

export type ClubFormState = {
    clubName: string;
    type: ClubType;
    city: string;
    zipCode: string;
    description: string;
    passion: number | undefined;
    customEnrollmentFlow: boolean;
    clubStatus: boolean;
    location: LocationState;
    eventsPortalEnabled: boolean;
    igniteEnabled: boolean;
    hostsVirtualEvents: boolean;
    hostsInPersonEvents: boolean;
    trackingInPersonActivitiesEnabled: boolean;
    trackingOnlineActivitiesEnabled: boolean;
    customerServiceEmail: string;
    customerServicePhone: string;
    verificationStatus: ClubVerificationStatus;
    inDirectory: boolean;
    inFinder: boolean;
    inGroupworks: boolean;
    phone: string;
    email: string;
    clubPlan: PlanOptions;
    welcomeEmailEnabled: boolean;
    emailVerificationConfirmationEmailEnabled: boolean;
    state: string;
    timeZone: string;
}

const initialState: ClubFormState = {
    clubName: "",
    type: ClubType.CLUB,
    city: "",
    zipCode: "",
    description: "",
    passion: undefined,
    customEnrollmentFlow: false,
    clubStatus: false,
    location: LocationState.Both,
    eventsPortalEnabled: true,
    igniteEnabled: true,
    hostsVirtualEvents: false,
    hostsInPersonEvents: true,
    trackingInPersonActivitiesEnabled: true,
    trackingOnlineActivitiesEnabled: true,
    customerServiceEmail: "",
    customerServicePhone: "",
    verificationStatus: ClubVerificationStatus.UNVERIFIED,
    inDirectory: false,
    inFinder: false,
    inGroupworks: false,
    phone: "",
    email: "",
    clubPlan: PlanOptions.MONTHLY,
    welcomeEmailEnabled: false,
    emailVerificationConfirmationEmailEnabled: false,
    state: "",
    timeZone: "",
}

interface ClubResponseModel {
    id: number;
    clubName: string;
    description: string;
    status: ClubStatus;
    timeZone: string;
    type: ClubType;
    customEnrollmentFlow: boolean;
    eventsPortalEnabled: boolean;
    igniteEnabled: boolean;
    hostsVirtualEvents: boolean;
    hostsInPersonEvents: boolean;
    trackingInPersonActivitiesEnabled: boolean;
    trackingOnlineActivitiesEnabled: boolean;
    customerServiceEmail: string;
    customerServicePhone: string;
    verificationStatus: number;
    inDirectory: boolean;
    inFinder: boolean;
    inGroupworks: boolean;
    phone: string;
    email: string;
    clubPlan: PlanOptions;
}

interface LocationResponseModel {
    id: number;
    city: string;
    countrySubdivision: string;
    postalCode: string;
}

interface PassionResponseModel {
    id: number;
    passionName: string;
}

enum ClubSettingType {
    WELCOME_EMAIL_ENABLED = 1,
    EMAIL_VERIFICATION_CONFIRMATION_EMAIL_ENABLED = 2
}

interface ClubSettingResponseModel {
    id: number;
    clubId: number;
    setting: ClubSettingType;
    value: boolean;
}

interface ClubByIdResponseModel {
    club: ClubResponseModel;
    location?: LocationResponseModel;
    passion?: PassionResponseModel;
    clubSettings: ClubSettingResponseModel[]
}

interface ClubFormProps {
    clubId: number | null;
}

export const getPhoneUnmask = (value: string | null) => value ? value.replace(/\D/g, '') : "";

export default function ClubForm(props: ClubFormProps) {
    const startOfTheFormRef = useRef<HTMLDivElement>(null);
    const [headerClubName, setHeaderClubName] = useState('...');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [formState, { email, text, select, checkbox, radio, tel }] = useFormState(initialState);
    const elementConfig = getElementConfig(formState);
    const [allPassions] = useAllPasions();
    const [loadingClub, setLoadingClub] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        if (formState.values.location === LocationState.Online) {
            formState.resetField('state')
            formState.resetField('city')
            formState.resetField('zipCode')
        }
    }, [formState.values.location])

    const createClub = async (body: object) => {
        setErrorMessage(null);

        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/createClub`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setErrorMessage('Failed to create club.');
            return;
        }
        history.push('/');
    };

    const editClub = async (body: object) => {
        setErrorMessage(null);

        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/UpdateClub`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setErrorMessage('Failed to update club.');
            return;
        }

        history.push('/');
    };

    const fetchClubById = async () => {
        setLoadingClub(true);
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/getClubDetailsByClubId?clubId=${props.clubId}`;
        const request = new Request(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setErrorMessage('Failed to fetch club.');
            return;
        }

        const responseModel: ClubByIdResponseModel = await response.json();

        setHeaderClubName(responseModel.club.clubName);

        formState.setField('clubName', responseModel.club.clubName);
        formState.setField('type', responseModel.club.type);
        formState.setField('description', responseModel.club.description);
        formState.setField('customEnrollmentFlow', responseModel.club.customEnrollmentFlow);
        formState.setField('clubStatus', responseModel.club.status === ClubStatus.ACTIVE ? true : false);
        formState.setField('timeZone', responseModel.club.timeZone);
        formState.setField('eventsPortalEnabled', responseModel.club.eventsPortalEnabled);
        formState.setField('igniteEnabled', responseModel.club.igniteEnabled);
        formState.setField('hostsVirtualEvents', responseModel.club.hostsVirtualEvents);
        formState.setField('hostsInPersonEvents', responseModel.club.hostsInPersonEvents);
        formState.setField('customerServiceEmail', responseModel.club.customerServiceEmail);
        formState.setField('customerServicePhone', responseModel.club.customerServicePhone);
        formState.setField('verificationStatus', responseModel.club.verificationStatus);
        formState.setField('inDirectory', responseModel.club.inDirectory);
        formState.setField('inFinder', responseModel.club.inFinder);
        formState.setField('inGroupworks', responseModel.club.inGroupworks);
        formState.setField('phone', responseModel.club.phone);
        formState.setField('email', responseModel.club.email);
        formState.setField('clubPlan', responseModel.club.clubPlan);

        if (responseModel.club.trackingInPersonActivitiesEnabled && responseModel.club.trackingOnlineActivitiesEnabled) {
            formState.setField('location', LocationState.Both);
        } else if (responseModel.club.trackingInPersonActivitiesEnabled) {
            formState.setField('location', LocationState.Offline);
        } else {
            formState.setField('location', LocationState.Online);
        }

        if (responseModel.club.trackingInPersonActivitiesEnabled && responseModel.location) {
            formState.setField('city', responseModel.location.city);
            formState.setField('zipCode', responseModel.location.postalCode);
            formState.setField('state', responseModel.location.countrySubdivision);
        }

        if (responseModel.passion) {
            formState.setField('passion', responseModel.passion.id);
        }

        const settingsByType = Object.assign({}, ...(responseModel.clubSettings || [])
          .map(setting => ({[setting.setting]: setting.value})));
        formState.setField('welcomeEmailEnabled', settingsByType[ClubSettingType.WELCOME_EMAIL_ENABLED] ?? true);
        formState.setField('emailVerificationConfirmationEmailEnabled', settingsByType[ClubSettingType.EMAIL_VERIFICATION_CONFIRMATION_EMAIL_ENABLED] ?? true);
        setLoadingClub(false);
    };

    useEffect(() => {
        if (props.clubId) {
            fetchClubById();
        }
    }, []);

    const onSubmit = () => {
        const fieldConfigsWithValidation = Object.entries(elementConfig)
            .filter(([, { validate }]) => validate)
            .map(([, values]) => (values));
        let hasSomeError = false;
        for (const { name, validate } of fieldConfigsWithValidation) {
            if (!validate) continue;
            const value = formState.values[name];
            const error = validate(value);
            if (error) {
                formState.setFieldError(name, error);
                hasSomeError = true;
            }
        }
        if (hasSomeError) {
            setErrorMessage('Please fix the errors in the form.');
            if (startOfTheFormRef.current) {
                startOfTheFormRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }
        const body = {
            clubName: formState.values.clubName,
            description: formState.values.description,
            passionId: formState.values.passion,
            timeZone: formState.values.timeZone,
            clubStatus: formState.values.clubStatus === true ? ClubStatus.ACTIVE : ClubStatus.INACTIVE,
            city: formState.values.city,
            countrySubdivision: formState.values.state,
            postalCode: formState.values.zipCode,
            shortCode: formState.values.shortCode,
            customEnrollmentFlow: formState.values.customEnrollmentFlow,
            type: formState.values.type,
            eventsPortalEnabled: formState.values.eventsPortalEnabled,
            igniteEnabled: formState.values.igniteEnabled,
            hostsVirtualEvents: formState.values.hostsVirtualEvents,
            hostsInPersonEvents: formState.values.hostsInPersonEvents,
            welcomeEmailEnabled: formState.values.welcomeEmailEnabled,
            emailVerificationConfirmationEmailEnabled: formState.values.emailVerificationConfirmationEmailEnabled,
            trackingInPersonActivitiesEnabled: (formState.values.location === LocationState.Offline || formState.values.location === LocationState.Both),
            trackingOnlineActivitiesEnabled: (formState.values.location === LocationState.Online || formState.values.location === LocationState.Both),
            customerServiceEmail: formState.values.customerServiceEmail,
            customerServicePhone: getPhoneUnmask(formState.values.customerServicePhone),
            verificationStatus: formState.values.verificationStatus,
            inDirectory: formState.values.inDirectory,
            inFinder: formState.values.inFinder,
            // inGroupworks not editable
            phone: getPhoneUnmask(formState.values.phone),
            email:formState.values.email,
            clubPlan: formState.values.clubPlan,
        }

        if (props.clubId) {
            const bodyWithClubId = { id: props.clubId, ...body };

            editClub(bodyWithClubId);
        } else {
            createClub(body);
        }
    }

    return (
        <div className="club-form" ref={startOfTheFormRef}>
            {errorMessage && <p className='club-form__error-message'>{errorMessage}</p>}
            <p className="club-form__title">
                {props.clubId
                    ? `Edit ${headerClubName}`
                    : 'Create New Club/Association'}
            </p>
            {
                loadingClub ?
                <Loading loading={loadingClub} size={100} message={"Loading Club Data..."}/>
                :
                <>
                <TextField
                {...text(elementConfig.clubName)}
                classes={{
                    root: 'club-form__field',
                }}
                InputLabelProps={{
                    htmlFor: 'club-name-id',
                    required: false,
                    classes: {
                        root: 'label-root',
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-form__field_error'
                    }
                }}
                InputProps={{
                    classes: {
                        input: 'club-form__field_bold'
                    }
                }}
                id='club-name-id'
                label='Name'
                error={formState.errors.clubName !== undefined}
                helperText={formState.errors.clubName}
                variant='outlined'
                size='small'
            />

            <TextField
                {...select(elementConfig.type)}
                className='club-form__select'
                variant="outlined"
                label='Type'
                InputLabelProps={{
                    htmlFor: 'type-id',
                }}
                InputProps={{
                    id: "type-id"
                }}
                classes={{ root: 'club-form__select' }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-form__field_error'
                    }
                }}
                error={formState.errors.type !== undefined}
                helperText={formState.errors.type}
                size='small'
                select={true}
            >
                {TYPE_OPTIONS.map((typeOption) => (
                    <MenuItem
                        key={typeOption.value}
                        value={typeOption.value}
                    >
                        {typeOption.label}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                {...text(elementConfig.description)}
                classes={{
                    root: 'club-form__field',
                }}
                InputLabelProps={{
                    htmlFor: 'club-desc-id',
                    required: false
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-form__field_error'
                    }
                }}
                rows={4}
                inputProps={{ maxLength: 1000 }}
                id='club-desc-id'
                required
                multiline
                variant="outlined"
                label='Description'
                error={formState.errors.description !== undefined}
                helperText={formState.errors.description}
                size='small'
            />

            <TextField
                {...select(elementConfig.passion)}
                className='club-form__select'
                variant="outlined"
                InputLabelProps={{
                    htmlFor: 'passion-id',
                }}
                label='Club Passion'
                select={true}
                classes={{ root: 'club-form__select' }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-form__field_error'
                    }
                }}
                InputProps={{
                    id: "passion-id"
                }}
                error={formState.errors.passion !== undefined}
                helperText={formState.errors.passion}
                size='small'
            >
                {allPassions.map((passion) => (
                    <MenuItem
                        key={passion.id}
                        value={passion.id}
                    >
                        {passion.passionName}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                {...select(elementConfig.timeZone)}
                className='club-form__select'
                variant="outlined"
                label='Time Zone'
                InputLabelProps={{
                    htmlFor: 'timezone-id',
                }}
                InputProps={{
                    id: "timezone-id"
                }}
                classes={{ root: 'club-form__select' }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-form__field_error'
                    }
                }}
                error={formState.errors.timeZone !== undefined}
                helperText={formState.errors.timeZone}
                size='small'
                select={true}
            >
                {Object.values(SupportedTimeZone).map((supportedTimeZone) => (
                    <MenuItem
                        key={supportedTimeZone}
                        value={supportedTimeZone}
                    >
                        {supportedTimeZone}
                    </MenuItem>
                ))}
            </TextField>

            <InputMask
                mask='(999) 999-9999'
                maskChar='*'
                {...tel(elementConfig.customerServicePhone)}
            >
                {(inputProps: any) =>
                    <TextField {...inputProps}
                               classes={{
                                   root: 'club-form__field',
                               }}
                               InputLabelProps={{
                                   htmlFor: 'customerServicePhone',
                                   required: false,
                                   classes: {
                                       root: 'label-root',
                                       error: 'label-error',
                                       focused: 'label-focused'
                                   }
                               }}
                               FormHelperTextProps={{
                                   classes: {
                                       root: 'club-form__field_error'
                                   }
                               }}
                               InputProps={{
                                   classes: {
                                       input: 'club-form__field_bold'
                                   }
                               }}
                               label='Customer Service Phone Number'
                               variant={"outlined"}
                               error={formState.errors.customerServicePhone !== undefined}
                               helperText={formState.errors.customerServicePhone}
                               size="small"
                    />
                }
            </InputMask>

            <TextField {...email(elementConfig.customerServiceEmail)}
                       classes={{
                           root: 'club-form__field',
                       }}
                       InputLabelProps={{
                           htmlFor: 'customerServiceEmail',
                           required: false,
                           classes: {
                               root: 'label-root',
                               error: 'label-error',
                               focused: 'label-focused'
                           }
                       }}
                       FormHelperTextProps={{
                           classes: {
                               root: 'club-form__field_error'
                           }
                       }}
                       InputProps={{
                           classes: {
                               input: 'club-form__field_bold'
                           }
                       }}
                       label='Customer Service Email Address'
                       variant={"outlined"}
                       error={formState.errors.customerServiceEmail !== undefined}
                       helperText={formState.errors.customerServiceEmail}
                       size="small"/>

            <InputMask
                mask='(999) 999-9999'
                maskChar='*'
                {...tel(elementConfig.phone)}
            >
                {(inputProps: any) =>
                    <TextField {...inputProps}
                               classes={{
                                   root: 'club-form__field',
                               }}
                               InputLabelProps={{
                                   htmlFor: 'phone',
                                   required: false,
                                   classes: {
                                       root: 'label-root',
                                       error: 'label-error',
                                       focused: 'label-focused'
                                   }
                               }}
                               FormHelperTextProps={{
                                   classes: {
                                       root: 'club-form__field_error'
                                   }
                               }}
                               InputProps={{
                                   classes: {
                                       input: 'club-form__field_bold'
                                   }
                               }}
                               label='Phone Number'
                               variant={"outlined"}
                               error={formState.errors.phone !== undefined}
                               helperText={formState.errors.phone}
                               size="small"
                    />
                }
            </InputMask>

            <TextField {...email(elementConfig.email)}
                       classes={{
                           root: 'club-form__field',
                       }}
                       InputLabelProps={{
                           htmlFor: 'email',
                           required: false,
                           classes: {
                               root: 'label-root',
                               error: 'label-error',
                               focused: 'label-focused'
                           }
                       }}
                       FormHelperTextProps={{
                           classes: {
                               root: 'club-form__field_error'
                           }
                       }}
                       InputProps={{
                           classes: {
                               input: 'club-form__field_bold'
                           }
                       }}
                       label='Email Address'
                       variant={"outlined"}
                       error={formState.errors.email !== undefined}
                       helperText={formState.errors.email}
                       size="small"/>
            <div className="subtitle">
                Custom Enrollment Flow
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.customEnrollmentFlow)}
                    className="switch"
                    id="switch-status-custom-enrollment-flow"
                />
                <label htmlFor="switch-status-custom-enrollment-flow">On</label>
            </div>
            <div className="club-setup-form__description">
                If checked, please ensure that you also create a primary short code for this entity
            </div>

            <div className="subtitle">
                Status
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.clubStatus)}
                    className="switch"
                    id="switch-status"
                />
                <label htmlFor="switch-status">Active</label>
            </div>

            <div className="subtitle">
                Display Club In Club Directory
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.inDirectory)}
                    className="switch"
                    id="switch-status"
                />
                <label htmlFor="switch-status">In Directory</label>
            </div>

            <div className="subtitle">
                Display Club In BYOC Finder
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.inFinder)}
                    className="switch"
                    id="switch-status"
                />
                <label htmlFor="switch-status">In Finder</label>
            </div>

            <div className="subtitle">
                Club exists in Groupworks
            </div>
            <div className="switch-wrapper">
                <Switch
                    disabled
                    {...checkbox(elementConfig.inGroupworks)}
                    className="switch"
                    id="switch-status"
                />
                <label htmlFor="switch-status">In Groupworks</label>
            </div>

            <div className="subtitle">
                Verification Status
            </div>
            <div className="switch-wrapper">
                <ManageStatus
                    status={formState.values.verificationStatus}
                    onUpdated={(newStatus: ClubVerificationStatus) => 
                        formState.setField("verificationStatus", newStatus)
                    }
                />
            </div>

            <div className="subtitle">
                Events Portal Enabled
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.eventsPortalEnabled)}
                    className="switch"
                    id="switch-status-events-portal-enabled"
                />
                <label htmlFor="switch-status-events-portal-enabled">On</label>
            </div>
            <div className="club-setup-form__description">
                If enabled, this club/association will show up in the events portal
                (searches for clubs may show this club/association, searches for events may show events from this club/association, etc).
            </div>

            <div className="subtitle">
                Activity Tracker Enabled
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.igniteEnabled)}
                    className="switch"
                    id="switch-status-ignite-enabled"
                />
                <label htmlFor="switch-status-ignite-enabled">On</label>
            </div>
            <div className="club-setup-form__description">
                If enabled, this club/association will show up in the activity tracker
                (members and leaders will be able to utilize activity tracking functionality for this club/association, this club/association may show up in Activity Tracker Club Finder, etc)
            </div>

            <div className="subtitle">
                Hosts Virtual Events
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.hostsVirtualEvents)}
                    className="switch"
                    id="switch-status-hosts_virtual_events"
                />
                <label htmlFor="switch-status-hosts_virtual_events">On</label>
            </div>
            <div className="club-setup-form__description">
                Currently, this is just informational. In the future, this may be used to show/hide the club in certain contexts, for example.
            </div>

            <div className="subtitle">
                Hosts In Person Events
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.hostsInPersonEvents)}
                    className="switch"
                    id="switch-status-hosts_in_person_events"
                />
                <label htmlFor="switch-status-hosts_in_person_events">On</label>
            </div>
            <div className="club-setup-form__description">
                Currently, this is just informational. In the future, this may be used to show/hide the club in certain contexts, for example.
            </div>

            <div className="subtitle">
                Welcome Email Enabled
            </div>
            <div className="switch-wrapper">
                <Switch
                  {...checkbox(elementConfig.welcomeEmailEnabled)}
                  className="switch"
                  id="switch-welcome-email-enabled"
                />
                <label htmlFor="switch-welcome-email-enabled">Enabled</label>
            </div>
            <div className="club-setup-form__description">
                If enabled, users who register through this club/association will receive a welcome email.
            </div>

            <div className="subtitle">
                Email Verification Confirmation Email Enabled
            </div>
            <div className="switch-wrapper">
                <Switch
                  {...checkbox(elementConfig.emailVerificationConfirmationEmailEnabled)}
                  className="switch"
                  id="switch-email-verification-confirmation-email-enabled"
                />
                <label htmlFor="switch-email-verification-confirmation-email-enabled">Enabled</label>
            </div>
            <div className="club-setup-form__description">
                If disabled, any users who are a member of this club/association will not get a confirmation email
                after verifying their email.
            </div>

            <div className="subtitle">
                Club Plan
            </div>
            <ClubDuesPlanOptions
                value={formState.values.clubPlan}
                onChange={(plan: PlanOptions) => formState.setField("clubPlan", plan)}
            />

            <div className="subtitle">
                Club location
            </div>

            <div className="radio-wrapper">
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Online)}
                        id="location-online"
                    />
                    <label htmlFor="location-online">
                        Online
                    </label>
                </div>
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Offline)}
                        id="location-offline"
                    />
                    <label htmlFor="location-offline">
                        In Person
                    </label>
                </div>
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Both)}
                        id="location-both"
                    />
                    <label htmlFor="location-both">
                        Both
                    </label>
                </div>
            </div>
            {(formState.values.location === LocationState.Offline || formState.values.location === LocationState.Both) && (
                <>
                    <div className="subtitle">
                        Location details
                    </div>
                    <TextField
                        {...text(elementConfig.city)}
                        classes={{
                            root: 'club-form__field',
                        }}
                        InputLabelProps={{
                            htmlFor: 'city-id',
                            required: false,
                            classes: {
                                root: 'label-root',
                                error: 'label-error',
                                focused: 'label-focused'
                            }
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: 'club-form__field_error'
                            }
                        }}
                        InputProps={{
                            classes: {
                                input: 'club-form__field_bold'
                            }
                        }}
                        id='city-id'
                        label='City'
                        error={formState.errors.city !== undefined}
                        helperText={formState.errors.city}
                        variant='outlined'
                        size='small'
                    />
                    <div className="club-form__field-wrapper">
                        <TextField
                            {...select(elementConfig.state)}
                            className='club-form__select'
                            variant="outlined"
                            label='State'
                            InputLabelProps={{
                                htmlFor: 'state-id',
                            }}
                            InputProps={{
                                id: "state-id"
                            }}
                            classes={{
                                root: 'club-form__select',
                            }}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'club-form__field_error'
                                }
                            }}
                            error={formState.errors.state !== undefined}
                            helperText={formState.errors.state}
                            size='small'
                            select={true}
                        >
                            {states.map(state => (
                                <MenuItem
                                    key={state.id}
                                    value={state.id}
                                >
                                    {state.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            {...text(elementConfig.zipCode)}
                            classes={{
                                root: 'club-form__field short',
                            }}
                            InputLabelProps={{
                                htmlFor: 'zip-code-id',
                                required: false,
                                classes: {
                                    root: 'label-root',
                                    error: 'label-error',
                                    focused: 'label-focused'
                                }
                            }}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'club-form__field_error'
                                }
                            }}
                            InputProps={{
                                classes: {
                                    input: 'club-form__field_bold'
                                }
                            }}
                            id='zip-code-id'
                            label='Zip Code'
                            error={formState.errors.zipCode !== undefined}
                            helperText={formState.errors.zipCode}
                            variant='outlined'
                            size='small'
                        />
                    </div>
                </>
            )}

            <div className='club-form__button-wrapper'>
                <Button
                    onClick={onSubmit}
                    className="club-form__button"
                    variant="contained"
                    classes={{
                        label: 'club-form__button-label',
                        disabled: 'club-form__button-disabled'
                    }}
                    // disabled={!submitEnabled()}
                >
                    {props.clubId ? 'Edit' : 'Create'}
                </Button>
            </div>
                </>
            }
            
        </div >
    )
}
