//
// This file fixes problems in JsJodaUtils from "@prinzdezibel/date-io-js-joda".
// Use this file instead of using that library directly.
//

import {LocalDate, LocalDateTime, LocalTime} from "@js-joda/core";
import JsJodaUtils from "@prinzdezibel/date-io-js-joda";

declare type T = typeof LocalDateTime | typeof LocalDate | typeof LocalTime;

function FixedJsJodaUtils(temporalType: T): any {
    const utils = JsJodaUtils(temporalType);

    // Mistakes in a few of the default implementations cause them to throw when
    // the value is a LocalDate.

    // Fix an issue that would occur when enabling the month picker as in
    // `<KeyboardDatePicker views={["year", "month", "date"]} ...`
    const defaultGetMonthArray = utils.prototype.getMonthArray;
    utils.prototype.getMonthArray = (date: any) => {
        if (!(date instanceof LocalDate)) {
            return defaultGetMonthArray(date);
        }
        const months: LocalDate[] = [];
        for (let i = 1; i <= 12; i++) {
            const startOfMonth = date.withMonth(i).withDayOfMonth(1);
            months.push(startOfMonth);
        }
        return months;
    };

    // Fix an issue that would occur when disabling future dates as in
    // `<KeyboardDatePicker disableFuture ...`
    const defaultIsAfterYear = utils.prototype.isAfterYear;
    utils.prototype.isAfterYear = (date: any, value: any) => {
        if (!(value instanceof LocalDate)) {
            return defaultIsAfterYear(date, value);
        }
        const startOfNextYear = value.plusYears(1).withDayOfYear(1);
        return !date.isBefore(startOfNextYear);
    };

    // Fix an issue that would occur when disabling past dates as in
    // `<KeyboardDatePicker disablePast ...`
    const defaultIsBeforeYear = utils.prototype.isBeforeYear;
    utils.prototype.isBeforeYear = (date: any, value: any) => {
        if (!(value instanceof LocalDate)) {
            return defaultIsBeforeYear(date, value);
        }
        const startOfThisYear = value.withDayOfYear(1);
        return date.isBefore(startOfThisYear);
    };

    return utils;
}

const LocalDateUtils = FixedJsJodaUtils(LocalDate);

export { FixedJsJodaUtils, LocalDateUtils };
