export enum SupportedTimeZone {
    EASTERN = "America/New_York",
    CENTRAL = "America/Chicago",
    MOUNTAIN = "America/Denver",
    ARIZONA = "America/Phoenix",
    PACIFIC = "America/Los_Angeles",
    ALASKA = "America/Juneau",
    HAWAII = "Pacific/Honolulu"
}

export function labelForTimeZone(timeZone: SupportedTimeZone): string {
    switch (timeZone) {
        case SupportedTimeZone.EASTERN:
            return "Eastern";
        case SupportedTimeZone.CENTRAL:
            return "Central";
        case SupportedTimeZone.MOUNTAIN:
            return "Mountain";
        case SupportedTimeZone.ARIZONA:
            return "Arizona";
        case SupportedTimeZone.PACIFIC:
            return "Pacific";
        case SupportedTimeZone.ALASKA:
            return "Alaska";
        case SupportedTimeZone.HAWAII:
            return "Hawaii";
    }
}
