import React, {useEffect} from "react";
import { RouteComponentProps } from "react-router-dom";

import ClubForm from '../../components/club/ClubForm';
import ClubMemberManagement from "../../components/club/ClubMemberManagement";

export default function ClubPage(props: RouteComponentProps<{ clubId?: string }>) {
    const clubId = props.match.params.clubId ? parseInt(props.match.params.clubId, 10) : null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
          <ClubForm clubId={clubId} />
          {clubId &&
              <ClubMemberManagement clubId={clubId} />
          }
        </div>
    );
}
