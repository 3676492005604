import React, {useContext} from "react";

import "./MyAccount.scss";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AdminContext} from "../../contexts/AdminContext";

interface MyAccountProps extends RouteComponentProps {
}

function MyAccount(props: MyAccountProps): JSX.Element {

    const {logoutAll, admin} = useContext(AdminContext);

    async function onClickLogout() {
        try {
            logoutAll().then(() => {
                    // todo: fix this
                    props.history.push("/login");
                }
            );
        } catch (e) {
            console.error("Error", e);
        }
    }

    return (
        <div className="MyAccount">
            { admin !== null &&
            <div className="MyAccount_Links">
                Hi, {admin?.email}!
                <div className="MyAccount_Logout"
                     onClick={onClickLogout}>Log Out</div>
            </div>
            }
        </div>
    );
}

export default withRouter(MyAccount);