import React from "react";
import "./SendTestEmail.scss";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/AdminService";

function SendTestEmail() : JSX.Element {

    async function sendTestEmail() {
        if (window.confirm("Do you want to send a test email to all admins?")) {
            const config = await loadConfig();
            const apiUrl = `${config.apiOrigin}/admin/utility/sendTestEmailToAdmins`;
            const request = new Request(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const response = await authenticatedFetch(request);
            if (response === null || !response.ok) {
                alert("There was an error.");
                return;
            }

            alert("Emails Sent.");
        }
    }

    return (<><button onClick={sendTestEmail}>Send Test Email</button></>);
}

export default SendTestEmail;
