import React, {useContext, useEffect} from "react";

import "./Layout.scss";
import Header from "./Header";
import Footer from "./Footer";
import {Route, Switch} from "react-router-dom";
import HomePage from "../../pages/home/HomePage";
import LoginPage from "../../pages/login/LoginPage";
import LogoutPage from "../../pages/logout/LogoutPage";
import ClubPage from "../../pages/club/ClubPage";
import NotFoundPage from "../../pages/notFound/NotFoundPage";
import {AdminContext} from "../../contexts/AdminContext";
import Version from "../common/Version";
import TechAdminPage from "../../pages/techAdmin/TechAdminPage";
import { useTranslation } from "react-i18next";

export default function Layout(): JSX.Element {
    // Required to init i18n. Must have at least one translation in use.
    const { t } = useTranslation('init');
    const {admin} = useContext(AdminContext);

    useEffect(
        () => {
        }, [])

    return (
        <div className={`Layout`}>
            {t("init")}
            <div className={`TopAccent`}/>
            <Header/>
            <div className={"Page"}>
                <div className="PageContent">
                    <Switch>
                        {admin !== null && <Route exact path="/" component={HomePage} /> }
                        {admin !== null && <Route exact path="/tech-admin" component={TechAdminPage} /> }
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/logout" component={LogoutPage} />
                        <Route path="/club/:clubId?" component={ClubPage} />
                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
            </  div>
            <Footer/>
            <Version/>
        </div>
    );
}
