import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    ChangeEvent,
    useRef
} from "react";
import {
    Select,
    MenuItem,
    Checkbox,
    FormControl,
    Tooltip,
} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useFormState } from 'react-use-form-state';

import { AdminContext } from "../../contexts/AdminContext";
import { loadConfig } from "../../services/ConfigService";
import Paging from "../../pages/home/Paging";
import ViewEditTextField from "./ViewEditTextField";

import "./ManageClubsList.scss";
import ManageStatus from "./ManageStatus";
import { ClubStatus, ClubType } from "../common/enum/club";

interface ShortCodeView {
    id: number
    clubId: number
    shortCode: string
    createdAt:string
    isPrimary: boolean
}

interface ClubAdminView {
    id: number
    urlFragment: string
    clubName: string
    status: ClubStatus
    organizationId: number | null
    isFeatured: boolean
    type: ClubType
    shortCodes: ShortCodeView[]
    customEnrollmentFlow: boolean
}

interface GetAllOrganizationsResponseBody  {
    allOrganizations: GetOrganizationResponseBody[]
}

interface GetOrganizationResponseBody  {
    id: number
    organizationName: string
}

interface Paged<T> {
    totalResultCount: number
    results: T[]
}

// Use this type for the `onChange` of a <Select>.
//
//     function onChangeFoo(event: ChangeEvent<MuiSelectElement>) {
//        ...
//     }
//
//     ...
//
//     <Select onChange={onChangeFoo}>
//
interface MuiSelectElement {
    name?: string | undefined;
    value: unknown; // wish this could be a more specific type such as string
}

interface ClubRowProps {
    club: ClubAdminView
    frontendOrigin: string | null
    organizations: GetOrganizationResponseBody[]
    updateClubStatus: (clubId: number, status: ClubStatus) => Promise<void>
    updateClubOrganization: (clubId: number, organizationId: number | null) => Promise<void>
    updateClubIsFeatured: (clubId: number, isFeatured: boolean) => Promise<void>
    updatePrimaryShortCode: (clubId: number, shortCodeId: number) => Promise<void>
    createShortCodeRequest: (clubId: number, shortCode: string) => Promise<Request>
    loadClubList: () => Promise<void>
    setMessage: (message: string) => void
    history: RouteComponentProps['history']
}

function ClubRow (props: ClubRowProps) {
    const [isShortCodeInputVisible, setShortCodeInputVisibleStatus] = useState(false);
    const prevShortCodes = useRef<Record<string, boolean>>({});

    function onPrimaryShortCodeChange (event: React.ChangeEvent<HTMLInputElement>) {
        // check if data from db is messy, no primary shortcode checked, or checked more than one primary shortcode
        const isDataMessy = Object.values(prevShortCodes.current).every(status => !status)
            || Object.values(prevShortCodes.current).filter(status => status).length !== 1;

        if (prevShortCodes.current[event.target.name] === true && !isDataMessy) {
            return;
        } else {
            let nextPrimaryShortCodeId: string | null = null;

            const nextShortCodesState = Object
                .keys(prevShortCodes.current)
                .reduce((prev, curr) => {
                    if (curr === event.target.name) {
                        nextPrimaryShortCodeId = event.target.name;

                        prev[curr] = true;
                    } else {
                        prev[curr] = false;
                    }

                    return prev;
                }, {} as Record<string, boolean>)

                prevShortCodes.current = nextShortCodesState;

            if (nextPrimaryShortCodeId) {
                props.updatePrimaryShortCode(props.club.id, parseInt(nextPrimaryShortCodeId, 10));
            }
        }
    }

    async function onSuccessSaveShortCode () {
        await props.loadClubList();

        setShortCodeInputVisibleStatus(false);
    }

    useEffect(() => {
        const shortCodesInitValues = props.club.shortCodes.reduce((prev, curr) => {
            prev[curr.id] = curr.isPrimary;
     
            return prev;
        }, {} as Record<string, boolean>);

        prevShortCodes.current = shortCodesInitValues;
    }, [props.club.shortCodes]);

    return (
        <div key={props.club.id} className="ManageClubsList_Row">
            <div className="ManageClubsList_GridItem">
                {props.frontendOrigin &&
                  <a target="_blank"
                     rel="noopener noreferrer"
                     href={`${props.frontendOrigin}/club/${encodeURIComponent(props.club.urlFragment)}`}>{props.club.clubName}</a>}
                {!props.frontendOrigin && <>{props.club.clubName}</>}
            </div>
            <div className="ManageClubsList_GridItem">
               {(props.club.type === ClubType.CLUB && 'Club') || (props.club.type === ClubType.ASSOCIATION && 'Association')}
            </div>
            <div className="ManageClubsList_GridItem ManageClubsList_Status">
                {/* <ManageStatus status={props.club.status} clubId={props.club.id} onUpdated={props.loadClubList}/> */}
                {props.club.status === ClubStatus.ACTIVE && (
                    <div className="ManageClubList_StatusText Active">Active</div>
                )}
                {props.club.status === ClubStatus.INACTIVE && (
                    <div className="ManageClubList_StatusText Inactive">Inactive</div>
                )}
                {props.club.status === ClubStatus.ACTIVE && (
                    <button onClick={() => props.updateClubStatus(props.club.id, ClubStatus.INACTIVE)}>
                        <b>Change to inactive</b>
                    </button>
                )}
                {props.club.status === ClubStatus.INACTIVE && (
                    <button onClick={() => props.updateClubStatus(props.club.id, ClubStatus.ACTIVE)}>
                        <b>Change to active</b>
                    </button>
                )}
            </div>
            <div className="ManageClubsList_GridItem">
                <Select value={props.club.organizationId === null ? "" : props.club.organizationId}
                        displayEmpty
                        onChange={async (event: ChangeEvent<MuiSelectElement>) => {
                            const selectedValue = event.target.value;
                            let newOrganizationId : number | null;

                            if (selectedValue === "") {
                                newOrganizationId = null;
                            } else if (typeof selectedValue === "number") {
                                newOrganizationId = selectedValue;
                            } else {
                                throw new Error("Unexpected value: " + selectedValue);
                            }

                            await props.updateClubOrganization(props.club.id, newOrganizationId);
                        }}>
                    <MenuItem value="">
                        None
                    </MenuItem>
                    {props.organizations.map(organization => (
                        <MenuItem key={organization.id} value={organization.id}>
                            {organization.organizationName}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="ManageClubsList_GridItem">
                <Checkbox disabled checked={props.club.customEnrollmentFlow}/>
            </div>
            <div className="ManageClubsList_GridItem">
                <div className="ManageClubsList_ShortCodes">
                    {props.club.shortCodes.length > 0 && <span>Primary</span>}
                    {props.club.shortCodes.map((shortCode) => (
                        <div key={shortCode.id} className="ManageClubsList_FormControlWrapper">
                            <FormControl>
                                <Checkbox
                                    required
                                    name={`${shortCode.id}`}
                                    onChange={onPrimaryShortCodeChange}
                                    checked={shortCode.isPrimary}/>
                                <Tooltip interactive title={shortCode.shortCode}>
                                    <label>{shortCode.shortCode}</label>
                                </Tooltip>
                            </FormControl>
                        </div>
                    ))}
                    <div className="ManageClubsList_AddShortCodeButton">
                        {isShortCodeInputVisible
                            ? <ViewEditTextField
                                isEditingMode
                                type="text"
                                onSuccessSave={onSuccessSaveShortCode}
                                setMessage={props.setMessage}
                                onCancel={() => setShortCodeInputVisibleStatus(false)}
                                confirmRequest={(shortCode: string) => props.createShortCodeRequest(props.club.id, shortCode)}/>
                            : <p onClick={() => setShortCodeInputVisibleStatus(true)}>
                                +Add Short Code
                            </p>}
                    </div>
                </div>
            </div>
            <div className="ManageClubsList_GridItem">
                <Checkbox checked={props.club.isFeatured}
                          onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                              const newIsFeatured = event.target.checked;
                              await props.updateClubIsFeatured(props.club.id, newIsFeatured);
                          }}/>
            </div>
            <div className="ManageClubsList_GridItem">
                <button onClick={() => props.history.push(`/club/${props.club.id}`)}>Edit</button>
            </div>
        </div>
    );
}

interface ManageClubsTableProps {
    clubs: ClubAdminView[];
    organizations: GetOrganizationResponseBody[];
    history: RouteComponentProps['history'];
    frontendOrigin: string | null;
    loadClubList: () => Promise<void>;
    updateClubStatus: (clubId: number, status: ClubStatus) => Promise<void>;
    updateClubOrganization: (clubId: number, organizationId: number | null) => Promise<void>;
    updateClubIsFeatured: (clubId: number, isFeatured: boolean) => Promise<void>;
    updatePrimaryShortCode: (clubId: number, shortCodeId: number) => Promise<void>;
    createShortCodeRequest: (clubId: number, shortCode: string) => Promise<Request>;
    setMessage: (message: string) => void;
    totalResults: number;
    resultsPerPage: number;
    page: number;
    setPage: (page: number) => void;
}

function ManageClubsTable(props: ManageClubsTableProps) {
    return (
        <div>
            <div className={"ManageClubsList_TableWrapper"}>
                <div className="ManageClubsList_Row ManageUserList_Headers">
                    <div className="ManageClubsList_GridItem">Name</div>
                    <div className="ManageClubsList_GridItem">Type</div>
                    <div className="ManageClubsList_GridItem">Status</div>
                    <div className="ManageClubsList_GridItem">Organization</div>
                    <div className="ManageClubsList_GridItem">Custom Enrollment Flow</div>
                    <div className="ManageClubsList_GridItem">Short Codes</div>
                    <div className="ManageClubsList_GridItem">Featured</div>
                    <div className="ManageClubsList_GridItem">Edit</div>
                </div>
                {props.clubs.map(club => (<ClubRow
                    key={club.id}
                    club={club}
                    frontendOrigin={props.frontendOrigin}
                    organizations={props.organizations}
                    loadClubList={props.loadClubList}
                    updateClubStatus={props.updateClubStatus}
                    updateClubOrganization={props.updateClubOrganization}
                    updateClubIsFeatured={props.updateClubIsFeatured}
                    updatePrimaryShortCode={props.updatePrimaryShortCode}
                    createShortCodeRequest={props.createShortCodeRequest}
                    setMessage={props.setMessage}
                    history={props.history}
                />))}
            </div>
            {props.totalResults} matching {props.totalResults === 1 ? "club/association" : "clubs/associations"}.
            {props.totalResults > props.resultsPerPage &&
            <Paging pageSize={props.resultsPerPage}
                    numberResults={props.totalResults}
                    currentPage={props.page}
                    onSelect={(newPage: number) => props.setPage(newPage)}/>}
        </div>
    );
}

function ManageClubsList(props: RouteComponentProps) {
    const [organizationList, setOrganizationList] = useState<GetOrganizationResponseBody[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(true);
    const [clubList, setClubList] = useState<ClubAdminView[]>([]);
    const [loadingClubs, setLoadingClubs] = useState(true);
    const {authenticatedFetch} = useContext(AdminContext);
    const [message, setMessage] = useState("");
    const [formState, { text, checkbox }] = useFormState({
        clubName: '',
        showClubs: true,
        showAssociations: true,
    });

    const [totalResults, setTotalResults] = useState(0);
    const [frontendOrigin, setFrontendOrigin] = useState<string | null>(null);

    useEffect(() => {
        async function loadFrontendOrigin() {
            const config = await loadConfig();
            setFrontendOrigin(config.frontendOrigin);
        }

        loadFrontendOrigin();
    }, []);

    const resultsPerPage = 25;
    const [page, setPage] = useState(0);

    const loadClubList = useCallback(async() => {
        const types = [];

        if (formState.values.showClubs) {
            types.push(ClubType.CLUB);
        }
        if (formState.values.showAssociations) {
            types.push(ClubType.ASSOCIATION);
        }

        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/searchClubs`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubName: formState.values.clubName,
                types,
                page,
                resultsPerPage
            })
        });

        const response = await authenticatedFetch(request);
        setLoadingClubs(false);
        if (response === null || !response.ok) {
            setMessage("Failed to load clubs.");
            return;
        }
        const responseJson: Paged<ClubAdminView> = await response.json();
        setClubList(responseJson.results);
        setTotalResults(responseJson.totalResultCount);
    }, [
        formState.values.showClubs,
        formState.values.showAssociations,
        formState.values.clubName,
        page,
    ])

    const updateClubStatus = async (clubId: number, status: ClubStatus) => {
        setMessage("Updating Club...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/updateClubStatus`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubId,
                status
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to update club.");
            return;
        }
        setMessage("Club updated");
        await loadClubList();
    };

    const updateClubOrganization = async (clubId: number, organizationId: number | null) => {
        setMessage("Updating Club...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/updateClubOrganization`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubId,
                organizationId
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to update club.");
            return;
        }
        setMessage("Club updated");
        await loadClubList();
    };

    const updateClubIsFeatured = async (clubId: number, isFeatured: boolean) => {
        setMessage("Updating Club...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/updateClubIsFeatured`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubId,
                isFeatured
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to update club.");
            return;
        }
        setMessage("Club updated");
        await loadClubList();
    };

    const createShortCodeRequest = async (clubId: number, shortCode: string) => {
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/CreateShortCode`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubId,
                shortCode,
            })
        });

        return request;
    };

    const updatePrimaryShortCode = async (clubId: number, shortCodeId: number) => {
        setMessage("Updating ShortCode...");
        const config = await loadConfig();
        const apiUrl = `${config.apiOrigin}/admin/clubs/SetPrimaryShortCode`;
        const request = new Request(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubId,
                shortCodeId
            })
        });

        const response = await authenticatedFetch(request);
        if (response === null || !response.ok) {
            setMessage("Failed to set a new primary shortcode for club.");
            return;
        }
        setMessage("A new primary shortcode is set.");
        await loadClubList();
    };

    useEffect(() => {

        async function loadOrganizationList() {
            const config = await loadConfig();
            const apiUrl = `${config.apiOrigin}/admin/clubs/getAllOrganizations`;
            const request = new Request(apiUrl);

            const response = await authenticatedFetch(request);
            setLoadingOrganizations(false);
            if (response === null || !response.ok) {
                setMessage("Failed to load organizations.");
                return;
            }
            const responseJson: GetAllOrganizationsResponseBody = await response.json();
            setOrganizationList(responseJson.allOrganizations);
        }

        loadOrganizationList();

    }, [authenticatedFetch]);

    useEffect(() => {
        loadClubList();
    }, [loadClubList]);

    let content;
    if (loadingClubs || loadingOrganizations) {
        content = <div>Loading Clubs...</div>;
    } else if (clubList.length === 0) {
        content = <div>No clubs/associations.</div>;
    } else {
        content = <ManageClubsTable
            clubs={clubList}
            organizations={organizationList}
            history={props.history}
            frontendOrigin={frontendOrigin}
            loadClubList={loadClubList}
            updateClubStatus={updateClubStatus}
            updateClubOrganization={updateClubOrganization}
            updateClubIsFeatured={updateClubIsFeatured}
            updatePrimaryShortCode={updatePrimaryShortCode}
            createShortCodeRequest={createShortCodeRequest}
            setMessage={setMessage}
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
            page={page}
            setPage={setPage}
        />
    }

    return (
        <div>
            <button onClick={() => props.history.push('/club')}>
                Create New Club/Association
            </button>
            <div className="ManageClubsList_FiltersWrapper">
                <div className="ManageClubsList_FormControlWrapper">
                    <FormControl>
                        <input {...text({
                            name: 'clubName',
                            onChange: () => setPage(0),
                        })} placeholder={"Search by name"}/>
                    </FormControl>
                </div>
                <div className="ManageClubsList_FormControlWrapper">
                    <FormControl>
                        <label>Show Clubs</label>
                        <Checkbox required
                                {...checkbox({
                                    name: 'showClubs',
                                    touchOnChange: true,
                                    onChange: () => setPage(0),
                                })}/>
                    </FormControl>
                </div>
                <div className="ManageClubsList_FormControlWrapper">
                    <FormControl>
                        <label>Show Associations</label>
                        <Checkbox required
                                {...checkbox({
                                    name: 'showAssociations',
                                    touchOnChange: true,
                                    onChange: () => setPage(0),
                                })}/>
                    </FormControl>
                </div>
            </div>
            <div>
                {message && <div className="ManageClubsList_Message">{message}</div>}
                {content}
            </div>
        </div>);
}

export default withRouter(ManageClubsList);
