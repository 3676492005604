import React, {useContext, useState} from "react";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import {IconButton, TextField} from "@material-ui/core";
import cn from 'classnames'

import {AdminContext} from "../../contexts/AdminContext";

import "./ViewEditTextField.scss";

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export interface ViewEditTextFieldProps {
    initialValue?: string,
    confirmRequest: (value: string) => Promise<Request>, 
    onSuccessSave: () => void,
    setMessage: (message: string) => void,
    type: 'email' | 'text',
    isEditingMode?: boolean;
    onCancel?: () => void;
}

const ViewEditTextField = ({
    isEditingMode,
    onSuccessSave,
    setMessage,
    type,
    confirmRequest,
    onCancel,
    initialValue = '',
}: ViewEditTextFieldProps) => {
    const {authenticatedFetch} = useContext(AdminContext);
    const [isEditing, setIsEditing] = useState(isEditingMode || false);
    const [value, setTextValue] = useState(initialValue);
    const [textError, setErrorText] = useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setTextValue(value);

        if (type === 'email' && !value?.match(EMAIL_REGEX)) {
            setErrorText('Please enter valid email');
        } else {
            setErrorText('')
        }
    }

    const onEditClick = () => {
        setIsEditing(true);
    }

    const onSaveClick = async () => {
        if (!isEditing || textError || initialValue === value) {
            return;
        }
        setIsEditing(false);

        const request = await confirmRequest(value);

        const response = await authenticatedFetch(request);
        if (response === null) {
            setIsEditing(true);
            setErrorText("Failed to add/update field");
            return;
        }
        if (!response.ok) {
            const responseText = await response.text();
            setIsEditing(true);
            setErrorText(responseText);
            return;
        }
        setMessage("Field added/updated.");
        onSuccessSave();
    }

    const onCancelClick = () => {
        setIsEditing(false);
        setErrorText('');
        setTextValue(initialValue);

        if (onCancel) {
            onCancel();
        }
    }

    return (
        <div className="ViewEditTextField_Wrapper">
            <TextField
                value={value}
                defaultValue={value}
                onChange={onChange}
                error={Boolean(textError)}
                helperText={textError}
                required
                InputProps={{
                    readOnly: !isEditing,
                    disableUnderline: !isEditing,
                    classes: {
                        input: 'ViewEditTextField_Input',
                        root: cn({ 'read-only': !isEditing }),
                        focused: 'focused'
                    }
                }}
            />
            {!isEditing &&
                <IconButton className='icon' onClick={() => onEditClick()}>
                    <EditIcon />
                </IconButton>
            }
            {isEditing && <>
                <IconButton className='icon' onClick={() => onSaveClick()}>
                    <CheckIcon />
                </IconButton>
                <IconButton className='icon' onClick={() => onCancelClick()}>
                    <CancelIcon />
                </IconButton>
            </>}
        </div>);
}

export default ViewEditTextField;